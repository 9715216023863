import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Cards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};

  margin-top: 4rem;
`

export const Card = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 9.5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.cards};
  border-radius: 1rem;

  img {
    width: 12rem;
    height: 12rem;
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};

      margin-top: 1rem;
    }

    span {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};

      margin-top: 2rem;
    }
  }
`
