import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

interface ContainerProps {
  height: boolean
}

interface OpenProps {
  isOpen: boolean
}

export const Container = styled.nav<ContainerProps>`
  z-index: 1;
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 5rem;

  padding: 1rem 2rem;

  background: ${(props) => props.theme.colors.primary};
  border-left: 1px solid ${(props) => props.theme.colors.mainBg};

  .right {
    display: flex;
    gap: 2rem;
    align-items: center;

    .open {
      display: flex;
      gap: 1rem;
      align-items: center;

      @media (max-width: 400px) {
        gap: 1rem;
        flex-direction: column-reverse;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 400px) {
    ${(props) =>
      props.height &&
      css`
        height: 100%;
        max-height: 10rem;
      `}
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 2.5rem;
      height: 2.5rem;

      color: ${(props) => props.theme.colors.white};
    }

    ${media.greaterThan('medium')`
      display: none;
    `}
  }
`

export const Open = styled.button<OpenProps>`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  width: 14rem;
  height: 3rem;

  background: ${(props) =>
    props.isOpen ? props.theme.colors.green : props.theme.colors.red};
  border-radius: 0.5rem;

  font-weight: 500;
  font-size: 1.4rem;

  transition: all 0.2s;

  color: ${(props) => props.theme.colors.white};

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const MenuOpen = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem;
`

export const OptionOpen = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 1.4rem;

  transition: all 0.2s;

  background: #ececec;
  color: #29323d;
`

export const Option = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  font-weight: 500;
  font-size: 1.4rem;

  transition: all 0.2s;

  color: #29323d;

  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`

export const Status = styled(Switch)``

export const InputTime = styled.input`
  padding: 5px;
  width: 100px;
  height: 30px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  text-align: center;

  color: #29323d;

  background: #ececec;
  border-radius: 2px;

  &::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 10px;
    text-align: center;

    color: #777777;
  }
`
