import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;
  margin-top: 1rem;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const ModuleStatus = styled(Switch)``

export const Printers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  margin-top: 2rem;

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Printer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  min-height: 80px;

  padding: 1.5rem;
`

export const PrinterLeft = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const PrinterRight = styled.div`
  display: flex;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
    margin-left: 1rem;
  }
`
