import React, { useState } from 'react'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { BsArrowRight } from 'react-icons/bs'

import { OrderProps, PrinterProps, PrintersModuleProps } from 'utils/props'
import { useMenuPrinter, useQzTray } from 'contexts/UtilsContext'
import { useOrderPrint } from 'hooks/useOrderPrint'
import usePersistedState from 'hooks/usePersistedState'
import { api } from 'services/api'
import errorHandler from 'services/errorHandler'
import { formatPrice } from 'utils/utils'

import OrderModal from './OrderModal'

import * as S from './styles'

interface Props {
  orders: OrderProps[]
  setOrders(newState: OrderProps[]): void
}

const OrdersTable: React.FC<Props> = ({ orders, setOrders }) => {
  const [order, setOrder] = useState<OrderProps>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [printersModule] = usePersistedState<PrintersModuleProps>(
    'printersModule',
    {
      component: 'MenuPrinter',
      printers: [],
      active: false
    }
  )

  const handleEditOrder = (orderValue: OrderProps) => {
    setOrder(orderValue)
    setIsOpen((prevState) => !prevState)
  }

  const listStatus = [
    {
      title: 'Aguardando',
      status: 'PLACED'
    },
    {
      title: 'Produção',
      status: 'CONFIRMED'
    },
    {
      title: 'Pronto',
      status: 'READY'
    },
    {
      title: 'Saiu para entrega',
      status: 'DISPATCHED'
    },
    {
      title: 'Entregue',
      status: 'CONCLUDED'
    },
    // {
    //   title: 'Pendente',
    //   status: 'PENDING'
    // },
    {
      title: 'Cancelado',
      status: 'CANCELLED'
    }
  ]

  const Right = ({ order }: { order: OrderProps }) => {
    const { menuPrinter, handlePrinter } = useMenuPrinter()
    const { qz } = useQzTray()
    const { html } = useOrderPrint(order)

    const handlePrintOrder = async () => {
      if (printersModule.active) {
        const printers: PrinterProps[] = printersModule.printers.reduce(
          (prevValue, value) => {
            const orderType =
              order.orderType === 'DELIVERY'
                ? 'deliveryMenuId'
                : order.orderType === 'TAKEOUT'
                ? 'takeoutMenuId'
                : 'indoorMenuId'

            if (
              value.activatedOrder ||
              order.items.some((item) =>
                value.categories[orderType]?.includes(item.categoryId)
              )
            ) {
              prevValue.push(value)
            }

            return prevValue
          },
          []
        )

        if (
          printersModule.component === 'MenuPrinter' &&
          menuPrinter.isActive()
        ) {
          const printersMenuPrinter = menuPrinter.printers()

          printers.forEach((printer) => {
            if (printersMenuPrinter.some((p) => p === printer.name)) {
              handlePrinter({
                printerName: printer.name,
                printerManufacturer: printer.manufacturer,
                columnSize: printer.columnSize,
                fontSize: printer.fontSize,
                type: 'Order',
                data: order
              })
            } else {
              const date = new Date()
              const mSec = date.getTime()
              const mywindow = window.open(
                '',
                `MENUZER01_${mSec}`,
                'height=400,width=600'
              )

              mywindow.document.write(html)

              mywindow.print()
              mywindow.focus()
              mywindow.onafterprint = () => {
                mywindow?.close()
              }

              setTimeout(() => {
                mywindow?.close()
              }, 500)
            }
          })
        } else if (
          printersModule.component === 'QzTray' &&
          qz.websocket.isActive()
        ) {
          const printersQzTray: string[] = await qz.printers.find()

          printers.forEach((printer) => {
            if (printersQzTray.some((p) => p === printer.name)) {
              const config = qz.configs.create(printer.name, {
                scaleContent: false,
                rasterize: false,
                border: {
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0
                },
                margins: 0
              })

              qz.print(config, [
                {
                  type: 'html',
                  format: 'plain',
                  data: html
                }
              ])
            } else {
              const date = new Date()
              const mSec = date.getTime()
              const mywindow = window.open(
                '',
                `MENUZER01_${mSec}`,
                'height=400,width=600'
              )

              mywindow.document.write(html)

              mywindow.document.close()
              mywindow.print()
              mywindow.focus()
              mywindow.onafterprint = () => {
                mywindow?.close()
              }

              setTimeout(() => {
                mywindow?.close()
              }, 500)
            }
          })
        } else {
          const date = new Date()
          const mSec = date.getTime()
          const mywindow = window.open(
            '',
            `MENUZER01_${mSec}`,
            'height=400,width=600'
          )

          mywindow.document.write(html)

          mywindow.document.close()
          mywindow.print()
          mywindow.focus()
          mywindow.onafterprint = () => {
            mywindow?.close()
          }

          setTimeout(() => {
            mywindow?.close()
          }, 500)
        }
      } else {
        const date = new Date()
        const mSec = date.getTime()
        const mywindow = window.open(
          '',
          `MENUZER01_${mSec}`,
          'height=400,width=600'
        )

        mywindow.document.write(html)

        mywindow.document.close()
        mywindow.print()
        mywindow.focus()
        mywindow.onafterprint = () => {
          mywindow?.close()
        }

        setTimeout(() => {
          mywindow?.close()
        }, 500)
      }
    }

    const handleStatusUpdateArrow = async () => {
      try {
        let orderUpdated = order

        if (order.orderStatus === 'PLACED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/confirm`
          )

          orderUpdated = data
          handlePrintOrder()
        } else if (order.orderStatus === 'CONFIRMED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/readyToPickup`
          )

          orderUpdated = data
        } else if (order.orderStatus === 'READY') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/${
              order.orderType === 'DELIVERY' ? 'dispatch' : 'concluded'
            }`
          )

          orderUpdated = data
        } else if (order.orderStatus === 'DISPATCHED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/concluded`
          )

          orderUpdated = data
        }

        setOrder({
          ...order,
          orderStatus: orderUpdated.orderStatus
        })
        setOrders(
          orders.map((item) => {
            if (item.id === order.id) {
              return { ...item, orderStatus: orderUpdated.orderStatus }
            }

            return item
          })
        )
      } catch (err) {
        errorHandler(err)
      }
    }

    return (
      <S.Buttons>
        <button type="button" onClick={() => handleEditOrder(order)}>
          Ver
        </button>

        {order.orderStatus !== 'CANCELLED' &&
          order.orderStatus !== 'CONCLUDED' && (
            <button type="button" onClick={handleStatusUpdateArrow}>
              <BsArrowRight />
            </button>
          )}
      </S.Buttons>
    )
  }

  return (
    <S.Container>
      {order && (
        <OrderModal
          handleEditOrder={handleEditOrder}
          isOpen={isOpen}
          order={order}
          setOrder={setOrder}
          orders={orders}
          setOrders={setOrders}
        />
      )}

      {listStatus.map((status) => {
        const listOrders = orders.filter((o) => o.orderStatus === status.status)

        return (
          <S.Table key={status.status}>
            <S.TableHeader>
              <h1>{status.title}</h1>

              <div>
                <span>{listOrders.length}</span>
              </div>
            </S.TableHeader>

            <S.Orders>
              {listOrders.map((o) => (
                <S.Order key={o.id}>
                  <S.Left>
                    <S.OrderHeader>
                      {o.salesChannel !== 'IFOOD' ? (
                        <h1>
                          {o.orderType === 'INDOOR' &&
                          o.salesChannel === 'TOTEM'
                            ? `${o.indoor.clientName}${
                                o.indoor.password
                                  ? ` - ${o.indoor.password}`
                                  : ''
                              }`
                            : o.customer?.name}
                        </h1>
                      ) : (
                        <h1>IFOOD</h1>
                      )}

                      <span>
                        {format(parseISO(o.createdAt), 'd MMM HH:mm', {
                          locale: ptBR
                        })}
                      </span>
                    </S.OrderHeader>

                    <S.OrderInformation>
                      {o.orderType === 'DELIVERY' ? (
                        <div>
                          <img
                            src="/img/icons/location.svg"
                            alt="Localização"
                          />

                          {o.salesChannel !== 'IFOOD' ? (
                            <span>{o.delivery.formattedAddress}</span>
                          ) : (
                            <span>
                              {o.delivery.deliveryAddress.formattedAddress}
                            </span>
                          )}
                        </div>
                      ) : o.orderType === 'TAKEOUT' ? (
                        <div>
                          <img
                            src="/img/icons/location.svg"
                            alt="Localização"
                          />

                          <span>Para retirar</span>
                        </div>
                      ) : o.orderType === 'INDOOR' &&
                        o.salesChannel === 'TOTEM' ? (
                        <div>
                          <img
                            src="/img/icons/location.svg"
                            alt="Localização"
                          />

                          <span>
                            Pedido realizado pelo totem de autoatendimento
                          </span>
                        </div>
                      ) : (
                        <div>
                          <img
                            src="/img/icons/location.svg"
                            alt="Localização"
                          />

                          <span>Mesa: {`${o.indoor.table}`}</span>
                        </div>
                      )}

                      {o.orderType === 'DELIVERY' && (
                        <>
                          {o.orderStatus === 'DISPATCHED' && (
                            <div>
                              <img
                                src="/img/icons/orders/helmet.svg"
                                alt="Entregador"
                              />

                              {/* <span>Por Guilherme</span> */}
                            </div>
                          )}
                        </>
                      )}
                    </S.OrderInformation>

                    <S.Footer>
                      <span>
                        {o.payments.methods[0].method}:{' '}
                        {formatPrice(o.total.orderAmount)}
                      </span>
                    </S.Footer>
                  </S.Left>

                  <S.Right>
                    <S.Index>
                      <span>{o.index}</span>
                    </S.Index>

                    {o.salesChannel === 'SITE' ? (
                      <img
                        src="/favicon.png"
                        alt="Pedido feito pelo Site"
                        title="Pedido feito pelo Site"
                        width={30}
                      />
                    ) : o.salesChannel === 'APPLICATION' ? (
                      <img
                        src="/img/icons/orders/application.svg"
                        alt="Pedido feito pelo Aplicativo"
                        title="Pedido feito pelo Aplicativo"
                      />
                    ) : (
                      o.salesChannel === 'IFOOD' && (
                        <img
                          src="/img/icons/orders/ifood.svg"
                          alt="Pedido feito pelo IFood"
                          title="Pedido feito pelo IFood"
                        />
                      )
                    )}

                    <Right order={o} />
                  </S.Right>
                </S.Order>
              ))}
            </S.Orders>
          </S.Table>
        )
      })}
    </S.Container>
  )
}

export default OrdersTable
