import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import * as S from './styles'

const schema = yup.object().shape({
  email: yup.string().email().required('Este campo é obrigatório!'),
  password: yup.string().min(6).required('Este campo é obrigatório!'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Senha e confirmar senha não são iguais!'
    )
})

interface FormDataProps {
  email: string
  password: string
  confirmPassword: string
}

const UserLogin: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setValue('email', site.service.employee.email ?? '', {
      shouldValidate: true
    })
  }, [setValue, site])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        if (site.service.employee.id) {
          await api('user-api').patch(`employees/${site.service.employee.id}`, {
            email: data.email,
            password: data.password
          })

          showToast({
            message: 'Login atualizado com sucesso!',
            type: 'success'
          })
        } else {
          await api('user-api').post(`employees`, {
            email: data.email,
            password: data.password,
            name: site.title,
            serviceId: site.serviceId
          })

          showToast({
            message: 'Login criado com sucesso!',
            type: 'success'
          })
        }

        setSite({
          ...site,
          service: {
            ...site.service,
            employee: {
              ...site.service.employee,
              email: data.email
            }
          }
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ type: 'error', message })
      } finally {
        setIsLoading(false)
      }
    },
    [setSite, showToast, site]
  )

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Col>
        <S.Label htmlFor="email">E-mail</S.Label>
        <S.Input
          id="email"
          name="email"
          placeholder="Ex: acaiplus@gmail.com"
          maxLength={40}
          className={errors.email && 'is-invalid'}
          {...register('email')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="password">Nova senha</S.Label>
        <S.Input
          id="password"
          name="password"
          type="password"
          placeholder="Ex: ********"
          className={errors.password && 'is-invalid'}
          maxLength={40}
          {...register('password')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="confirmPassword">Confirmar senha</S.Label>
        <S.Input
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          placeholder="Ex: ********"
          className={errors.confirmPassword && 'is-invalid'}
          {...register('confirmPassword')}
        />
      </S.Col>

      <S.Buttons>
        <S.Button disabled={isLoading}>Salvar</S.Button>
      </S.Buttons>
    </S.Container>
  )
}

export default UserLogin
