import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > div {
    position: relative;

    display: flex;
    gap: 1.5rem;
    align-items: center;

    @media (max-width: 640px) {
      flex-direction: column;

      align-items: start;

      > button {
        position: absolute;
        top: 0;
        right: 0;

        margin: 0;
      }
    }

    > div {
      display: flex;
      gap: 1rem;
      flex-direction: column;

      label,
      span {
        color: ${(props) => props.theme.colors.text.cards};
      }

      input {
        display: block;
        width: 100%;
        height: calc(4rem + 2px);
        padding: 1rem 1.2rem;
        font-size: 1.5rem;
        color: #cad2db;
        background-color: #020216e8;
        background-clip: padding-box;
        border: 2px solid #222;
        border-radius: 0.375rem;
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: 0.4s;

        outline: none;

        &:hover,
        &:focus {
          border: 2px solid #2d81d3;
        }

        &.is-invalid {
          border-color: ${(props) => props.theme.colors.red};
        }
      }

      .sizes {
        display: flex;
        gap: 1rem;

        button {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 3rem;
          height: 3rem;

          background: ${(props) => props.theme.colors.red};
          border-radius: 50%;

          font-weight: bold;
          font-size: 18px;

          color: #ffffff;

          &.active {
            background: ${(props) => props.theme.colors.green};
          }
        }
      }
    }

    > button {
      margin: 30px 0 0 30px;

      svg {
        width: 2rem;
        height: 2rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }
  }

  > button {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    width: max-content;

    font-weight: 500;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`
