import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { FaArrowsAlt } from 'react-icons/fa'

import { FiTrash2 } from 'react-icons/fi'

import { OptionProps } from 'utils/props'
import { useStock } from 'contexts/StockContext'
import { taxableUnits } from 'utils/taxableUnits'

import * as S from './styles'

interface Props {
  option: OptionProps
  options: OptionProps[]
  setOptions(newState: OptionProps[]): void
}

const Option: React.FC<Props> = ({ option, options, setOptions }) => {
  const themeContext = useContext(ThemeContext)
  const { stocks } = useStock()

  const [name, setName] = useState('')
  const [unit, setUnit] = useState<'UN' | 'KG' | 'G'>('UN')
  const [posCode, setPosCode] = useState('')
  const [price, setPrice] = useState(0)
  const [available, setAvailable] = useState(true)

  useEffect(() => {
    setName(option.name)
    setPosCode(option.posCode)
    setUnit(option?.unit || 'UN')
    setPrice(option.price.value.value)
    setAvailable(option.available)
  }, [option])

  const handleOptionRemove = useCallback(() => {
    const optionIndex = options.findIndex((item) => item.id === option.id)

    const updatedOptions = [...options]
    const filteredOptions = updatedOptions.filter((_, i) => i !== optionIndex)

    setOptions(filteredOptions)
  }, [option.id, options, setOptions])

  const handleOptionChangeName = useCallback(
    (value: string) => {
      const optionIndex = options.findIndex((item) => item.id === option.id)
      const newOptions = [...options]

      Object.assign(newOptions[optionIndex], {
        name: value
      })

      setOptions(newOptions)
      setName(value)
    },
    [options, setOptions, option.id]
  )

  const handleOptionChangeTaxableUnit = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value as 'UN' | 'KG' | 'G'
      const optionIndex = options.findIndex((item) => item.id === option.id)
      const newOptions = [...options]

      Object.assign(newOptions[optionIndex], {
        unit: value
      })

      setOptions(newOptions)
      setUnit(value)
    },
    [option, options, setOptions]
  )

  const handleOptionChangePosCode = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value
      const optionIndex = options.findIndex((item) => item.id === option.id)
      const newOptions = [...options]

      if (value === 'remove') {
        Object.assign(newOptions[optionIndex], {
          posCode: null
        })

        setOptions(newOptions)
        setPosCode(null)
        return
      }

      Object.assign(newOptions[optionIndex], {
        posCode: value
      })

      setOptions(newOptions)
      setPosCode(value)
    },
    [option, options, setOptions]
  )

  const handleOptionChangePrice = useCallback(
    (value: number) => {
      const optionIndex = options.findIndex((item) => item.id === option.id)
      const newOptions = [...options]

      Object.assign(newOptions[optionIndex], {
        price: {
          ...newOptions[optionIndex].price,
          value: {
            ...newOptions[optionIndex].price.value,
            value
          }
        }
      })

      setOptions(newOptions)
      setPrice(value)
    },
    [option.id, options, setOptions]
  )

  const handleOptionChangeAvailable = useCallback(() => {
    const optionIndex = options.findIndex((item) => item.id === option.id)
    const newOptions = [...options]

    Object.assign(newOptions[optionIndex], {
      available: !available
    })

    setOptions(newOptions)
    setAvailable((prevState) => !prevState)
  }, [options, available, setOptions, option.id])

  return (
    <S.Container>
      <S.Content>
        <button type="button" className="handle" style={{ gridArea: 'sort' }}>
          <FaArrowsAlt />
        </button>

        <S.Col style={{ gridArea: 'name' }}>
          <S.Label htmlFor={`name_${option.id}`}>
            Nome da opção (obrigatório)
          </S.Label>
          <S.Input
            id={`name_${option.id}`}
            placeholder="Ex: Embalado para levar"
            className={name.trim().length <= 0 ? 'is-invalid' : ''}
            value={name}
            onChange={(e) => handleOptionChangeName(e.currentTarget.value)}
          />
        </S.Col>

        <S.Col style={{ gridArea: 'unit' }}>
          <S.Label htmlFor="unit">Unidade Tributável</S.Label>
          <S.Select onChange={handleOptionChangeTaxableUnit}>
            {taxableUnits.map((taxableUnit) => (
              <option
                key={taxableUnit.unit}
                selected={taxableUnit.unit === unit}
                value={taxableUnit.unit}
              >
                {taxableUnit.unit} - {taxableUnit.name}
              </option>
            ))}
          </S.Select>
        </S.Col>

        <S.Col style={{ gridArea: 'posCode' }}>
          <S.Label htmlFor={`posCode_${option.id}`}>Código PDV</S.Label>
          <S.Select
            id={`posCode_${option.id}`}
            onChange={handleOptionChangePosCode}
          >
            {posCode && posCode.trim().length > 0 ? (
              <option value="remove">Remover estoque</option>
            ) : (
              <option selected={!posCode} disabled hidden>
                Selecione um estoque
              </option>
            )}

            {stocks
              // .filter(stock =>
              //   stock.methodsToAdd.some(methodToAdd => methodToAdd.type === unit)
              // )
              .map((stock) => (
                <option
                  key={stock.id}
                  selected={stock.code === posCode}
                  value={stock.code}
                >
                  {stock.code} - {stock.name}
                </option>
              ))}
          </S.Select>
        </S.Col>

        <S.Col style={{ gridArea: 'price' }}>
          <S.Label htmlFor={`price_${option.id}`}>Preço (obrigatório)</S.Label>
          <S.InputMoney
            id={`price_${option.id}`}
            money
            currency="BRL"
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                }
              }
            }}
            value={price}
            onChange={(e, maskedValue: number) =>
              handleOptionChangePrice(maskedValue)
            }
          />
        </S.Col>

        <S.Col style={{ gridArea: 'status' }}>
          <S.Label htmlFor={`status_${option.id}`}>Status</S.Label>

          <S.Status
            id={`status_${option.id}`}
            onChange={handleOptionChangeAvailable}
            checked={available}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={60}
            handleDiameter={12}
            offColor={themeContext.colors.red}
            onColor={themeContext.colors.green}
          />
        </S.Col>

        <button
          type="button"
          title="Remover"
          onClick={handleOptionRemove}
          style={{ gridArea: 'delete' }}
        >
          <FiTrash2 />
        </button>
      </S.Content>
    </S.Container>
  )
}

export default Option
