import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { TotemSelfServiceProps } from 'utils/props'
import { useTotem } from 'contexts/TotemContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import LogoInput from './LogoInput'

import * as S from './styles'

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const Details: React.FC = () => {
  const { totem, setTotem } = useTotem()
  const { showToast } = useToast()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [previewLogo, setPreviewLogo] = useState(null)

  useEffect(() => {
    setImage(totem.logo)
    setPreviewLogo(totem.logo)

    setValue('name', totem.name, { shouldValidate: true })
  }, [setValue, totem])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        let logoUrl = typeof image === 'string' ? image : null

        if (image && typeof image !== 'string') {
          const data = new FormData()
          data.append('file', image)

          const response = await api('user-api').post('files/images', data)

          const { url } = response.data
          logoUrl = url
        }

        const { data: response } = await api(
          'user-api'
        ).put<TotemSelfServiceProps>(`totemsselfservice/${totem.id}`, {
          ...data,
          logo: logoUrl
        })

        setTotem({
          ...totem,
          ...response
        })

        showToast({
          type: 'success',
          message: 'Detalhes atualizado com sucesso!'
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ type: 'error', message })
      } finally {
        setIsLoading(false)
      }
    },
    [image, setTotem, showToast, totem?.id]
  )

  const handleCopyCode = useCallback(
    (code: string) => {
      const input = document.body.appendChild(document.createElement('input'))
      input.value = code
      input.focus()
      input.select()
      document.execCommand('copy')
      input.parentNode.removeChild(input)

      showToast({
        type: 'success',
        message: 'Código de identicação copiado com sucesso!'
      })
    },
    [showToast]
  )

  return (
    <S.Container>
      <S.Upload>
        <LogoInput
          setImage={setImage}
          previewImage={previewLogo}
          setPreviewImage={setPreviewLogo}
        />
      </S.Upload>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Col>
          <S.Label htmlFor="id">
            Identificação do totem (clique para copiar)
          </S.Label>
          <S.Copy id="id" onClick={() => handleCopyCode(totem.id)}>
            {totem.id}
          </S.Copy>
        </S.Col>

        <S.Col>
          <S.Label htmlFor="title">Nome do totem</S.Label>
          <S.Input
            id="name"
            name="name"
            placeholder="Ex: Açaí Comandante"
            className={errors.name && 'is-invalid'}
            {...register('name')}
          />
        </S.Col>

        <S.Buttons>
          <S.Button disabled={isLoading}>Salvar</S.Button>
        </S.Buttons>
      </S.Form>
    </S.Container>
  )
}

export default Details
