import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { SiteProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import LogoInput from './LogoInput'
import FaviconInput from './FaviconInput'

import * as S from './styles'

const schema = yup.object().shape({
  title: yup.string().required('Este campo é obrigatório'),
  slogan: yup.string(),
  phone: yup
    .string()
    .min(15, 'Deve ter exatamente 15 dígitos')
    .required('Este campo é obrigatório'),
  minimumOrderValue: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  title: string
  slogan: string
  phone: string
  minimumOrderValue: string
}

const Details: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [previewLogo, setPreviewLogo] = useState(null)
  const [favicon, setFavicon] = useState(null)
  const [previewFavicon, setPreviewFavicon] = useState(null)

  const [phone, setPhone] = useState(null)
  const [minimumOrderValue, setMinimumOrderValue] = useState(0)

  useEffect(() => {
    setImage(site.logo)
    setPreviewLogo(site.logo)
    setFavicon(site.favicon)
    setPreviewFavicon(site.favicon)

    setValue('title', site.title, { shouldValidate: true })
    setValue('slogan', site.slogan, { shouldValidate: true })
    setValue('phone', site.phone, { shouldValidate: true })
    setPhone(site.phone)
    setValue('minimumOrderValue', String(site.minimumOrderValue), {
      shouldValidate: true
    })
    setMinimumOrderValue(site.minimumOrderValue)
  }, [setValue, site])

  const handleChangePhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhone(e.target.value)
      setValue('phone', e.target.value, { shouldValidate: true })
    },
    [setValue]
  )

  const handleChangeMinimumOrderValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, maskedValue: number) => {
      setMinimumOrderValue(maskedValue)
      setValue('minimumOrderValue', String(maskedValue), {
        shouldValidate: true
      })
    },
    [setValue]
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        let logoUrl = typeof image === 'string' ? image : null

        if (image && typeof image !== 'string') {
          const data = new FormData()
          data.append('file', image)

          const response = await api('user-api').post('files/images', data)

          const { url } = response.data
          logoUrl = url
        }

        let faviconUrl = typeof favicon === 'string' ? favicon : null

        if (favicon && typeof favicon !== 'string') {
          const data = new FormData()
          data.append('file', favicon)

          const response = await api('user-api').post('files/images', data)

          const { url } = response.data
          faviconUrl = url
        }

        const { data: response } = await api('user-api').put<SiteProps>(
          `sites/${site.id}`,
          {
            ...data,
            logo: logoUrl,
            favicon: faviconUrl
          }
        )

        setSite({
          ...site,
          ...response
        })

        showToast({
          type: 'success',
          message: 'Detalhes atualizado com sucesso!'
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ type: 'error', message })
      } finally {
        setIsLoading(false)
      }
    },
    [favicon, image, setSite, showToast, site]
  )

  return (
    <S.Container>
      <S.Upload>
        <LogoInput
          setImage={setImage}
          previewImage={previewLogo}
          setPreviewImage={setPreviewLogo}
        />
        <FaviconInput
          setImage={setFavicon}
          previewImage={previewFavicon}
          setPreviewImage={setPreviewFavicon}
        />
      </S.Upload>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Col>
          <S.Label htmlFor="title">Nome da sua loja</S.Label>
          <S.Input
            id="title"
            name="title"
            placeholder="Ex: Açaí Comandante"
            className={errors.title && 'is-invalid'}
            {...register('title')}
          />
        </S.Col>

        <S.Col>
          <S.Label htmlFor="slogan">Slogan da sua loja</S.Label>
          <S.Input
            id="slogan"
            name="slogan"
            placeholder="Ex: Açaí Comandante"
            className={errors.slogan && 'is-invalid'}
            {...register('slogan')}
          />
        </S.Col>

        <S.Col>
          <S.Label htmlFor="phone">Telefone de contato</S.Label>
          <S.InputMask
            id="phone"
            name="phone"
            placeholder="Ex: (00) 00000-0000"
            mask="(99) 99999-9999"
            className={errors.phone && 'is-invalid'}
            value={phone}
            onChange={handleChangePhone}
          />
        </S.Col>

        <S.Col>
          <S.Label htmlFor="minimumOrderValue">Pedido mínimo</S.Label>
          <S.InputMoney
            id="minimumOrderValue"
            name="minimumOrderValue"
            money
            currency="BRL"
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                }
              }
            }}
            value={minimumOrderValue}
            onChange={handleChangeMinimumOrderValue}
            className={errors.minimumOrderValue && 'is-invalid'}
          />
        </S.Col>

        <S.Col>
          <S.Label htmlFor="domain">Domínio</S.Label>
          <S.Input
            id="dmain"
            name="domain"
            value={site.domain || 'Nenhum domínio configurado'}
            disabled
          />
        </S.Col>

        <S.Buttons>
          <S.Button disabled={isLoading}>Salvar</S.Button>
        </S.Buttons>
      </S.Form>
    </S.Container>
  )
}

export default Details
