import React, { useCallback, useEffect, useState, useContext } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'

import { TableProps } from 'utils/props'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'

import Spinner from 'components/Spinner'
import TableModal from './TableModal'
import QrCode from 'components/QrCode'

import * as S from './styles'

const Indoor: React.FC = () => {
  const { site } = useSite()
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)

  const [isLoading, setIsLoading] = useState(true)
  const [tables, setTables] = useState<TableProps[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tableSelected, setTableSelected] = useState<TableProps>(null)

  const loadIndoorData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await api('user-api').get(
        `tables/${site.service.id}/list`
      )

      setTables(data)
    } finally {
      setIsLoading(false)
    }
  }, [site.service.id])

  useEffect(() => {
    loadIndoorData()
  }, [loadIndoorData])

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, table: TableProps) => {
      setTableSelected(table)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleModal = useCallback((table: TableProps = null) => {
    setTableSelected(table)
    setIsOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalTableEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(tableSelected)
  }, [handleMenuOptionsClose, handleToggleModal, tableSelected])

  const handleTableDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir a mesa "${tableSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          setIsLoading(true)

          try {
            const updatedTables = [...tables]
            const filteredTables = updatedTables.filter(
              (item) => item.id !== tableSelected.id
            )

            try {
              setTables(filteredTables)

              await api('user-api').delete(`tables/${tableSelected.id}`)
            } catch (err) {
              const message =
                err?.response?.data?.error ||
                'Por Favor entrar em contato com suporte.'

              showToast({ message, type: 'error' })
            }
          } finally {
            setIsLoading(false)
          }
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    handleMenuOptionsClose,
    showToast,
    tableSelected?.id,
    tableSelected?.name,
    tables
  ])

  const handleUpdateStatusTable = useCallback(
    ({ id }) => {
      const updatedTables = tables.map((item) => {
        if (item.id === id) {
          return { ...item, available: !item.available }
        }

        return item
      })

      setTables(updatedTables)
    },
    [tables]
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalTableEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleTableDelete}>Excluir</StyledMenuItem>
        {/* <StyledMenuItem
          onClick={handleCategoryCopy}
        >
          Duplicar
        </StyledMenuItem> */}
      </StyledMenu>

      <TableModal
        isOpen={isOpen}
        table={tableSelected}
        tables={tables}
        setTables={setTables}
        handleToggle={handleToggleModal}
      />

      <S.Container>
        <S.Title>Mesas</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleModal()}>
            <FiPlus /> Adicionar mesa
          </S.ButtonAdd>
        </S.Options>

        <S.Tables>
          {tables.map((item) => (
            <S.Table key={item.id}>
              <S.TableHeader>
                <S.TableHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.TableHeaderLeft>

                <S.TableHeaderRight>
                  <S.TableStatus
                    onChange={() => handleUpdateStatusTable({ id: item.id })}
                    checked={item.available}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.TableHeaderRight>
              </S.TableHeader>

              <QrCode
                link={`${site.domain || `https://menuzer01.com/${site.slug}`}/${
                  item.id
                }`}
              />
            </S.Table>
          ))}
        </S.Tables>
      </S.Container>
    </>
  )
}

export default Indoor
