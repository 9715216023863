import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Options = styled.div`
  display: flex;
  align-items: center;

  margin-top: 2rem;

  ${media.lessThan('small')`
    flex-direction: column;
    justify-content: center;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  svg {
    margin-right: 1rem;
  }
`

export const Tables = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  margin-top: 3rem;

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  padding: 1.5rem;
`

export const TableHeader = styled.header`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`

export const TableHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const TableHeaderRight = styled.div`
  display: flex;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};

    margin-left: 1rem;
  }
`

export const TableStatus = styled(Switch)``
