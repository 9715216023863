import styled from 'styled-components'
import media from 'styled-media-query'
import { ReactSortable } from 'react-sortablejs'
import Switch from 'react-switch'

export const Container = styled(ReactSortable)`
  display: flex;
  flex-direction: column;

  margin-top: 3rem;
`

export const Category = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

export const CategoryHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 1rem;
  border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
`

export const CategoryHeaderLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  h1 {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const CategoryHeaderRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const CategoryTitle = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    display: none;
  `};

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  h1 {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const CategoryTitleLeft = styled.div`
  margin-left: 4rem;

  display: flex;
  gap: 10rem;
  align-items: center;
  justify-content: space-between;
`

export const CategoryTitleRight = styled.div`
  display: flex;
  gap: 12rem;
  align-items: center;
  justify-content: space-between;
`

export const CategoryStatus = styled(Switch)``
