import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ThemeContext } from 'styled-components'

import { api } from 'services/api'
import { useModule } from 'contexts/ModuleContext'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

const schema = yup.object().shape({
  publicKey: yup.string().required('A Public Key é obrigatório'),
  accessToken: yup.string().required('O Access Token é obrigatória')
})

interface FormDataProps {
  publicKey?: string
  accessToken?: string
}

interface Props extends RouteComponentProps {}

const MercadoPagoModule: React.FC<Props> = ({ history }) => {
  const { showToast } = useToast()
  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const themeContext = useContext(ThemeContext)

  const { module, setModule } = useModule()

  const [isLoading, setIsLoading] = useState(false)
  const previewPublicKey = watch('publicKey')
  const previewAccessToken = watch('accessToken')

  useEffect(() => {
    setValue('publicKey', module.mercadopagoModule?.publicKey, {
      shouldValidate: true
    })
    setValue('accessToken', module.mercadopagoModule?.accessToken, {
      shouldValidate: true
    })
  }, [module, setValue])

  const handleCancel = () => history.push('/dashboard/modules/')

  const handleButtonHelper = () =>
    window.open('https://www.mercadopago.com.br/developers/panel', '_blank')

  const handleUpdateStatus = useCallback(async () => {
    try {
      const mercadopagoModuleData = {
        ...module.mercadopagoModule,
        active: !module.mercadopagoModule.active
      }

      setModule({
        ...module,
        mercadopagoModule: mercadopagoModuleData
      })

      const { data: response } = await api('user-api').put(
        `modules/mercadopago/${module.mercadopagoModule.id}`,
        mercadopagoModuleData
      )

      showToast({
        type: 'success',
        message: 'Módulo atualizado com sucesso!'
      })
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Aconteceu um erro!'
      })
    }
  }, [module, setModule, showToast])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        const mercadopagoModuleData = {
          publicKey: data.publicKey.trim().length > 0 ? data.publicKey : null,
          accessToken:
            data.accessToken.trim().length > 0 ? data.accessToken : null
        }

        const { data: response } = await api('user-api').put(
          `modules/mercadopago/${module.mercadopagoModule.id}`,
          mercadopagoModuleData
        )

        setModule({
          ...module,
          mercadopagoModule: response
        })

        showToast({
          type: 'success',
          message: 'Módulo atualizado com sucesso!'
        })
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Aconteceu um erro!'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [module, setModule, showToast]
  )

  return (
    <S.Container>
      <S.Title>
        <h1>Mercado Pago</h1>

        <S.Helper>
          Para pegar as credencias, clique{' '}
          <button type="button" onClick={handleButtonHelper}>
            aqui
          </button>
        </S.Helper>

        <S.ModuleStatus
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={60}
          handleDiameter={12}
          offColor={themeContext.colors.red}
          onColor={themeContext.colors.green}
          checked={module.mercadopagoModule.active}
          disabled={
            isLoading ||
            !previewPublicKey ||
            previewPublicKey?.trim().length === 0 ||
            !previewAccessToken ||
            previewAccessToken?.trim().length === 0
          }
          onChange={handleUpdateStatus}
        />
      </S.Title>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Col>
          <S.Label htmlFor="publicKey">
            Digite aqui o public key da mercado pago.
          </S.Label>

          <S.Input
            id="publicKey"
            name="publicKey"
            className={errors.publicKey && 'is-invalid'}
            disabled={isLoading}
            {...register('publicKey')}
          />
        </S.Col>

        <S.Col>
          <S.Label htmlFor="accessToken">
            Digite aqui a access token da mercado pago.
          </S.Label>

          <S.Input
            id="accessToken"
            name="accessToken"
            type="password"
            className={errors.accessToken && 'is-invalid'}
            disabled={isLoading}
            {...register('accessToken')}
          />
        </S.Col>

        <S.Buttons>
          <S.Button type="button" cancel onClick={handleCancel}>
            Cancelar
          </S.Button>

          <S.Button
            type="submit"
            disabled={
              isLoading ||
              !previewPublicKey ||
              previewPublicKey?.trim().length === 0 ||
              !previewAccessToken ||
              previewAccessToken?.trim().length === 0
            }
          >
            Salvar
          </S.Button>
        </S.Buttons>
      </S.Form>
    </S.Container>
  )
}

export default MercadoPagoModule
