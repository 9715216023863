import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  min-width: 80rem;

  ${media.lessThan('medium')`
    padding: 2rem;
    width: 100%;
    min-width: 100%;
  `}

  h1 {
    font-style: normal;
    font-size: 2rem;

    color: #f7fdff;
  }

  p {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2rem;

    span {
      font-style: 400;
      font-size: 2rem;

      color: #f7fdff;
    }

    button {
      font-style: 700;
      font-size: 2rem;

      color: ${(props) => props.theme.colors.red};
    }
  }
`

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 5rem 0;

  h1 {
    font-style: normal;
    font-size: 2rem;

    color: #f7fdff;
  }
`

export const Services = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 7rem;

  background: #8257e6;
  border-radius: 1rem;

  font-weight: bold;
  font-size: 2rem;

  color: #f7fdff;
`
