// @ts-nocheck
import React, { useState, useCallback, useContext } from 'react'
import moment from 'moment-timezone'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { ThemeContext } from 'styled-components'
import Tour from 'reactour'

import { chartBarOptions, chartLineOptions } from './ChartOptions'
import ChartTooltip from './ChartTooltip'
import { parseBarData, parseLineData, parseTooltipData } from 'utils/chartUtils'
import { numberBrFormatter } from 'utils/utils'

import DatePicker from 'components/DatePicker'

import * as S from './styles'

const Home = () => {
  const themeContext = useContext(ThemeContext)
  const [chart, setChart] = useState('line')
  const [period, setPeriod] = useState({
    startDate: moment(),
    endDate: moment()
  })
  const [selectedDate, setSelectedDate] = useState('today')
  const tourConfig = [
    {
      selector: '[data-tut="reactour__style"]',
      content: function DemoHelperComponent() {
        return (
          <div>
            <h1>Oi</h1>
          </div>
        )
      },
      style: {
        backgroundColor: 'black',
        color: 'white'
      }
    }
  ]

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return

    if (!startDate.isValid() || !endDate.isValid()) return

    setPeriod({ startDate, endDate })
  }, [])

  const [data, setData] = useState({
    status: 'success',
    chart: {
      dates: [
        '2021-04-20T00:00:00-03:00',
        '2021-04-21T00:00:00-03:00',
        '2021-04-22T00:00:00-03:00',
        '2021-04-23T00:00:00-03:00',
        '2021-04-24T00:00:00-03:00',
        '2021-04-25T00:00:00-03:00',
        '2021-04-26T00:00:00-03:00'
      ],
      faturamento: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ],
      custoProdutos: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ],
      ticketMedio: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ],
      lucroLiquido: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ],
      transactionTax: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ]
    }
  })

  const handleChartSelect = useCallback(
    (chartSelected) => setChart(chartSelected),
    []
  )

  const axisYTickLeft = useCallback(
    (tick) => (
      <g transform={`translate(${tick.x - 22},${tick.y}) rotate(-45)`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: themeContext.colors.text.cards,
            fontFamily: 'Roboto',
            fontSize: 12
          }}
        >
          {`R$ ${numberBrFormatter(tick.value, 0)}`}
        </text>
      </g>
    ),
    [themeContext]
  )

  const axisYTickBottom = useCallback(
    (tick) => (
      <g transform={`translate(${tick.x},${tick.y})`}>
        <text
          transform="translate(0,10) rotate(-45)"
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: themeContext.colors.text.cards,
            fontFamily: 'Roboto',
            fontSize: 11
          }}
        >
          {tick.value}
        </text>
      </g>
    ),
    [themeContext]
  )

  return (
    <S.Container>
      <S.DatePicker>
        <DatePicker
          startDate={period.startDate}
          endDate={period.endDate}
          onDate={onDate}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      </S.DatePicker>

      <S.Cards>
        <S.Card>
          <div className="content">
            <header>
              <h1>Faturamento</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(0, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color blue" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Custos dos produtos</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(0, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color green" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Lucro dos adicionais</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(0, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color purple" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Lucro líquido</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(0, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color orange" />
        </S.Card>
      </S.Cards>

      <S.Grid>
        <S.Chart>
          <div className="content">
            <header>
              <div>
                <h3>GRÁFICO DE PERFORMANCE</h3>
                <p>Veja como está sua evolução</p>
              </div>
              <div>
                <select
                  name="chart"
                  id="chart"
                  defaultValue="line"
                  onChange={(e) => handleChartSelect(e.target.value)}
                >
                  <option value="line">Gráfico de Linha</option>
                  <option value="bar">Gráfico de Barra</option>
                </select>
              </div>
            </header>

            <div className="chart-content">
              {chart === 'line' ? (
                <ResponsiveLine
                  data={parseLineData(data.chart)}
                  {...chartLineOptions}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    renderTick: axisYTickLeft
                  }}
                  axisBottom={{
                    renderTick: axisYTickBottom
                  }}
                  tooltip={({
                    point: {
                      data: { notParsedDate }
                    }
                  }) => (
                    <ChartTooltip
                      data={parseTooltipData(data?.chart, notParsedDate)}
                    />
                  )}
                />
              ) : (
                <ResponsiveBar
                  data={parseBarData(data?.chart)}
                  {...chartBarOptions}
                  axisLeft={{
                    renderTick: axisYTickLeft
                  }}
                  axisBottom={{
                    renderTick: axisYTickBottom
                  }}
                  tooltip={({ data: { notParsedDate } }) => (
                    <ChartTooltip
                      data={parseTooltipData(data?.chart, notParsedDate)}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <S.Color className="pink" />
        </S.Chart>

        <S.Resume>
          <div className="content">
            <div>
              <span>Lucro líquido:</span>

              <span>R$ {numberBrFormatter(0, 2)}</span>
            </div>

            <div>
              <span>Total de pedidos:</span>

              <span>0</span>
            </div>

            <div>
              <span>Margem de lucro:</span>

              <span>0,0%</span>
            </div>

            <div>
              <span>Pedidos pendentes:</span>

              <span>R$ {numberBrFormatter(0, 2)}</span>
            </div>
          </div>

          <S.Color className="light-green" />
        </S.Resume>
      </S.Grid>
    </S.Container>
  )
}

export default Home
