import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { io, Socket } from 'socket.io-client'

interface MenuPrinterProviderProps {
  children: React.ReactNode
}

interface MenuPrinterContextData {
  menuPrinter: {
    isActive: () => boolean
    printers: () => string[]
  }
  handlePrinter: (data: any) => void
}

const MenuPrinterContext = createContext({} as MenuPrinterContextData)

const MenuPrinterProvider: React.FC<MenuPrinterProviderProps> = ({
  children
}) => {
  const socket = useRef<Socket>()
  const [listPrinters, setListPrinters] = useState([])

  useEffect(() => {
    socket.current = io('http://localhost:8000')
    socket.current.on('connect', () => {
      console.log(
        'Established connection with MenuPrinter on ws://localhost:8000'
      )
    })
    socket.current.on('close', () => {
      console.log('Closed connection with MenuPrinter')
    })
    socket.current.on('connect_error', (error) => {
      console.log(error.message)
    })
    socket.current.emit('printers')
    socket.current.on('printers', (data) => {
      setListPrinters(data)
    })

    return () => {
      socket.current.close()
    }
  }, [])

  const isActive = () => socket.current.connected
  const printers = () => listPrinters

  const handlePrinter = (data: any) => {
    socket.current.emit('printing', data)
  }

  return (
    <MenuPrinterContext.Provider
      value={{
        menuPrinter: {
          isActive,
          printers
        },
        handlePrinter
      }}
    >
      {children}
    </MenuPrinterContext.Provider>
  )
}

function useMenuPrinter(): MenuPrinterContextData {
  const context = useContext(MenuPrinterContext)

  if (!context) {
    throw new Error('useMenuPrinter must be used within a MenuPrinterContext')
  }

  return context
}

export { MenuPrinterContext, MenuPrinterProvider, useMenuPrinter }
