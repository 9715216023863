import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;

    &.is-invalid {
      img,
      div {
        box-shadow: 0px 1px 5px ${(props) => props.theme.colors.red};
      }
    }

    &:hover {
      opacity: 0.7;
    }

    img,
    div {
      width: 20rem;
      height: 20rem;
      object-fit: cover;

      padding: 0.8rem;

      background: ${(props) => props.theme.colors.primary};
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 1.5rem;

      ${media.lessThan('small')`
        width: 100%;
        height: 100%;
        max-height: 20rem;
      `};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        width: 40px;
        height: 40px;

        color: #dddddd;
      }

      strong {
        font-size: 1.2rem;
        text-align: center;

        color: #dddddd;

        margin-top: 0.2rem;
      }
    }

    input {
      display: none;
    }
  }

  > button {
    font-size: 1.5rem;

    color: ${({ theme }) => theme.colors.text.cards};

    width: 100%;
  }
`
