import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'

import { StockProps } from 'utils/props'
import { useSite } from './SiteContext'
import { api } from 'services/api'
import { socket } from 'services/socket'
import { taxableUnits } from 'utils/taxableUnits'

interface StockContextData {
  isLoading: boolean
  stocks: StockProps[]
  setStocks(stocks: StockProps[]): void
  lowStock: number
}

interface CountItemProps {
  posCode: string
  quantityInStock:
    | 'quantityInStockUN'
    | 'quantityInStockKG'
    | 'quantityInStockG'
  amount: number
}

interface CountProps {
  count: CountItemProps[]
  type: 'ENTRACE' | 'EXIT'
}

const StockContext = createContext({} as StockContextData)

const StockProvider: React.FC = ({ children }) => {
  const { site } = useSite()
  const [isLoading, setIsLoading] = useState(true)
  const [stocks, setStocks] = useState<StockProps[]>([])
  const lowStock = useMemo(
    () =>
      stocks.reduce((totalAmount, i) => {
        const quantity = taxableUnits.reduce((taxableUnit, item) => {
          if (i[item.quantityInStock] < i[item.minimumQuantityInStock]) {
            return taxableUnit + 1
          }

          return taxableUnit
        }, 0)

        return totalAmount + quantity
      }, 0),
    [stocks]
  )

  const loadStockData = useCallback(async () => {
    if (site) {
      setIsLoading(true)

      try {
        const { data } = await api('user-api').get(
          `stocks/${site.serviceId}/list`
        )

        setStocks(data)
      } finally {
        setIsLoading(false)
      }
    }
  }, [site])

  useEffect(() => {
    loadStockData()
  }, [loadStockData])

  return (
    <StockContext.Provider
      value={{
        isLoading,
        stocks,
        setStocks,
        lowStock
      }}
    >
      {children}
    </StockContext.Provider>
  )
}

function useStock(): StockContextData {
  const context = useContext(StockContext)

  if (!context) {
    throw new Error('useStock must be used within a StockContext')
  }

  return context
}

export { StockContext, StockProvider, useStock }
