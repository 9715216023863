import React from 'react'

import { MenuPrinterProvider, useMenuPrinter } from './MenuPrinterContext'
import { QzTrayProvider, useQzTray } from './QzTrayContext'

export const UtilsProvider = ({ children }): JSX.Element => (
  <MenuPrinterProvider>
    <QzTrayProvider>{children}</QzTrayProvider>
  </MenuPrinterProvider>
)

export { useMenuPrinter, useQzTray }
