import React, { useCallback } from 'react'

import { StockProps } from 'utils/props'
import { useWindowSize } from 'hooks/useWindowSize'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: StockProps | null): void
  stock: StockProps
}

const AddStockModal: React.FC<Props> = ({ isOpen, handleToggle, stock }) => {
  const [width, height] = useWindowSize()
  // const handleResetStats = useCallback(() => {
  //   reset()
  //   setAvailable(true)
  //   setIsLoading(false)
  // }, [reset])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      // handleResetStats()
    },
    [handleToggle]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>{stock?.name}</h1>
          <p>Adicionar estoque</p>
        </S.Header>

        {stock?.methodsToAdd.map((method, index) => (
          <div key={index}>
            Uma {method.type} é equivalente a{' '}
            {method.quantityToConvert.amountToAdd}{' '}
            {method.quantityToConvert.type}
          </div>
        ))}
      </S.Content>
    </S.Container>
  )
}

export default AddStockModal
