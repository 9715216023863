import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useModule } from 'contexts/ModuleContext'

import Spinner from 'components/Spinner'
import AnalyticsModal from './AnalyticsModal'

import * as S from './styles'

interface Props extends RouteComponentProps {}

const AnalyticsModule: React.FC<Props> = () => {
  const { module } = useModule()

  // const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [analytics, setAnalytics] = useState(null)

  // const loadModuleData = useCallback(async () => {
  //   setIsLoading(true)

  //   try {
  //     const { data } = await api('user-api').get(`modules/${site.service.id}`)

  //     setModule(data.analyticsModule)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }, [site])

  // useEffect(() => {
  //   loadModuleData()
  // }, [loadModuleData])

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleSelectAnalytics = useCallback((item) => {
    setAnalytics(item)
    setIsOpen(true)
  }, [])

  // if (isLoading) {
  //   return <Spinner />
  // }

  return (
    <>
      <AnalyticsModal
        isOpen={isOpen}
        handleToggle={handleToggle}
        analytics={analytics}
      />

      <S.Container>
        <S.Title>Pixel de Rastreamento</S.Title>

        <S.Cards>
          <button
            type="button"
            onClick={() => handleSelectAnalytics('facebook')}
          >
            <S.Card active={!!module.analyticsModule.facebookPixelId}>
              <img
                src="/img/icons/modules/analytics/facebook.svg"
                alt="Facebook"
              />
            </S.Card>
          </button>

          <button
            type="button"
            onClick={() => handleSelectAnalytics('googleAnalytics')}
          >
            <S.Card active={!!module.analyticsModule.googleAnalyticsId}>
              <img
                src="/img/icons/modules/analytics/google-analytics.svg"
                alt="Facebook"
              />
            </S.Card>
          </button>
        </S.Cards>
      </S.Container>
    </>
  )
}

export default AnalyticsModule
