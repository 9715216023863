import React from 'react'

import { Container } from './styles'

interface Props {
  mainBg?: boolean
}

const MenuCard: React.FC<Props> = ({ children, mainBg }) => (
  <Container mainBg={mainBg}>{children}</Container>
)

export default MenuCard
