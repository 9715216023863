import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { AreaProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: AreaProps | null): void
  areas: AreaProps[]
  setDeliveryAreas(areas: AreaProps[]): void
  area?: AreaProps
  handleUpdateArea(areas: AreaProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const AreaModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  areas,
  setDeliveryAreas,
  area,
  handleUpdateArea
}) => {
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const [deliveryFee, setDeliveryFee] = useState(0)
  const [available, setAvailable] = useState(true)

  const handleResetStats = useCallback(() => {
    reset()
    setAvailable(true)
    setDeliveryFee(0)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (area) {
      setValue('name', area.name, { shouldValidate: true })
      setDeliveryFee(area.deliveryFee)
      setAvailable(area.available)
    }
  }, [setValue, area])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleChangeDeliveryFeeValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>, maskedValue: number) => {
      setDeliveryFee(maskedValue)
    },
    []
  )

  const handleAvailableRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setAvailable(value === 'true')
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const areaData = {
        id: area ? area.id : uuidv4(),
        name: data.name,
        deliveryFee,
        available
      }

      const updatedAreas = [...areas]

      if (area) {
        const areaIndex = areas.findIndex((c) => c.id === area.id)

        updatedAreas[areaIndex] = areaData as AreaProps
      } else {
        updatedAreas.push(areaData as AreaProps)
      }

      try {
        setDeliveryAreas(
          updatedAreas.sort((a, b) =>
            a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
          )
        )
        handleUpdateArea(
          updatedAreas.sort((a, b) =>
            a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
          )
        )

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      area,
      deliveryFee,
      available,
      areas,
      handleUpdateArea,
      setDeliveryAreas,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo área'}
          </h1>
          <p>Detalhes da área</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="name">Nome da área</S.Label>
            <S.Input
              id="name"
              name="name"
              placeholder="Ex: Centro"
              className={errors.name && 'is-invalid'}
              {...register('name')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="deliveryFee">Preço da entrega</S.Label>

            <S.InputMoney
              id="deliveryFee"
              money
              currency="BRL"
              config={{
                locale: 'pt-BR',
                formats: {
                  number: {
                    BRL: {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                }
              }}
              value={deliveryFee}
              onChange={handleChangeDeliveryFeeValue}
            />
          </S.Col>

          <S.Col>
            <S.Label>Disponibilidade</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="true"
                  onChange={handleAvailableRadio}
                  defaultChecked={available}
                />
                <span>
                  <div>
                    <div>Disponível</div>
                    <div>
                      A área ficará disponível para que o cliente possa
                      selecionar
                    </div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="false"
                  onChange={handleAvailableRadio}
                  defaultChecked={!available}
                />
                <span>
                  <div>
                    <div>Indisponível</div>
                    <div>
                      A área ficará indisponível para que o cliente possa
                      selecionar
                    </div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default AreaModal
