import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { FiPlus } from 'react-icons/fi'

import { CategoryProps, OptionGroupProps, OptionProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'

import Option from './Option'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: CategoryProps | null): void
  optionGroup: OptionGroupProps[]
  setOptionGroup(newState: OptionGroupProps[]): void
  option: OptionGroupProps
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  posCode: yup.string()
})

interface FormDataProps {
  name: string
}

const OptionModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  optionGroup,
  setOptionGroup,
  option
}) => {
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const [tabActive, setTabActive] = useState('DETAILS')
  const previewName = watch('name')
  const [isRequired, setIsRequired] = useState(false)
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(1)
  const [options, setOptions] = useState<OptionProps[]>([])

  useEffect(() => {
    if (option) {
      setValue('name', option.name)
      setIsRequired(option.min !== 0)
      setMinQuantity(option.min)
      setMaxQuantity(option.max)
      setOptions(option.options)
    }
  }, [option, setValue])

  const handleResetStats = useCallback(() => {
    reset()
    setOptions([])
    setMaxQuantity(1)
    setMinQuantity(0)
    setIsRequired(false)
    setTabActive('DETAILS')
    setIsLoading(false)
  }, [reset])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleSelectTabActive = useCallback((tab: string) => {
    setTabActive(tab)
  }, [])

  const handleRequiredRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === 'true'

      if (!value) {
        setMinQuantity(0)
      } else {
        setMinQuantity(1)
      }

      setIsRequired(value)
    },
    []
  )

  const handleDecrementButton = useCallback(
    (button: string) => {
      if (button === 'minQuantity' && minQuantity > 1) {
        setMinQuantity((prevState) => prevState - 1)
      } else if (maxQuantity > 1) {
        if (maxQuantity - 1 < minQuantity) {
          setMinQuantity((prevState) => prevState - 1)
        }

        setMaxQuantity((prevState) => prevState - 1)
      }
    },
    [minQuantity, maxQuantity]
  )

  const handleIncrementButton = useCallback((button: string) => {
    if (button === 'minQuantity') {
      setMinQuantity((prevState) => prevState + 1)
    } else {
      setMaxQuantity((prevState) => prevState + 1)
    }
  }, [])

  const handleAddOption = useCallback(() => {
    const optionData = {
      id: uuidv4(),
      name: '',
      price: {
        value: {
          currency: 'BRL',
          value: 0
        },
        originalValue: null
      },
      posCode: null,
      available: true
    }

    setOptions((prevState) => [...prevState, optionData as OptionProps])
  }, [])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const { name } = data

      const optionGroupData = {
        id: option ? option.id : uuidv4(),
        name,
        max: maxQuantity,
        min: minQuantity,
        options,
        available: true
      }

      const newOptions = [...optionGroup]

      if (option) {
        const optionIndex = optionGroup.findIndex((c) => c.id === option.id)

        Object.assign(newOptions[optionIndex], {
          ...optionGroupData
        })
      } else {
        newOptions.push(optionGroupData)
      }

      try {
        setOptionGroup(newOptions)
        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      option,
      maxQuantity,
      minQuantity,
      options,
      optionGroup,
      setOptionGroup,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#293949',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo complemento'}
          </h1>

          <S.OptionsButtons>
            <button
              type="button"
              className={tabActive === 'DETAILS' ? 'active' : ''}
              onClick={() => handleSelectTabActive('DETAILS')}
            >
              Detalhes
            </button>

            <button
              type="button"
              className={tabActive === 'OPTIONS' ? 'active' : ''}
              onClick={() => handleSelectTabActive('OPTIONS')}
            >
              Complementos
            </button>
          </S.OptionsButtons>
        </S.Header>

        <S.Form>
          {tabActive === 'DETAILS' ? (
            <S.Table>
              <S.Col>
                <S.Label htmlFor="name">
                  Nome do complemento (obrigatório)
                </S.Label>
                <S.Input
                  id="name"
                  name="name"
                  placeholder="Ex: Cobertura"
                  className={errors.name && 'is-invalid'}
                  {...register('name')}
                />
              </S.Col>

              <S.Col>
                <S.Label htmlFor="required">Obrigatoriedade</S.Label>

                <S.RadioGroup>
                  <label>
                    <input
                      name="required"
                      type="radio"
                      value="false"
                      onChange={handleRequiredRadio}
                      defaultChecked={isRequired === false}
                    />
                    <span>
                      <div>
                        <div>Opcional</div>
                        <div>O cliente pode ou não selecionar os itens</div>
                      </div>
                    </span>
                  </label>
                  <label>
                    <input
                      name="required"
                      type="radio"
                      value="true"
                      onChange={handleRequiredRadio}
                      defaultChecked={isRequired === true}
                    />
                    <span>
                      <div>
                        <div>Obrigatório</div>
                        <div>
                          O cliente deve selecionar 1 ou mais itens para
                          adicionar o pedido ao carrinho
                        </div>
                      </div>
                    </span>
                  </label>
                </S.RadioGroup>
              </S.Col>

              <S.Col>
                <S.Label>Quantidade</S.Label>

                <S.OptionQuantity>
                  <div>
                    <span>Mínimo</span>
                    <div>
                      <button
                        type="button"
                        disabled={!isRequired || minQuantity === 1}
                        onClick={() => handleDecrementButton('minQuantity')}
                      >
                        -
                      </button>
                      <span>{minQuantity}</span>
                      <button
                        type="button"
                        disabled={!isRequired || minQuantity + 1 > maxQuantity}
                        onClick={() => handleIncrementButton('minQuantity')}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div>
                    <span>Máximo</span>
                    <div>
                      <button
                        type="button"
                        disabled={maxQuantity === 1}
                        onClick={() => handleDecrementButton('maxQuantity')}
                      >
                        -
                      </button>
                      <span>{maxQuantity}</span>
                      <button
                        type="button"
                        onClick={() => handleIncrementButton('maxQuantity')}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </S.OptionQuantity>
              </S.Col>
            </S.Table>
          ) : (
            <>
              <S.ButtonAddOption>
                <S.ButtonAdd type="button" onClick={handleAddOption}>
                  <FiPlus /> Adicionar complemento
                </S.ButtonAdd>
              </S.ButtonAddOption>

              <S.Options
                handle=".handle"
                animation={150}
                list={options}
                setList={(newState) => setOptions(newState)}
              >
                {options.map((item) => (
                  <Option
                    key={item.id}
                    option={item}
                    options={options}
                    setOptions={setOptions}
                  />
                ))}
              </S.Options>
            </>
          )}

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="button"
              disabled={
                isLoading ||
                options.length <= 0 ||
                !options.every((item) => item.name.length > 0)
              }
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default OptionModal
