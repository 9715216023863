import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 4rem;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Cards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(5, 1fr);
    column-gap: 4rem;
  `}
`

interface CardProps {
  active: boolean
}

export const Card = styled.div<CardProps>`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-width: 30rem;
  height: 100%;
  min-height: 10rem;

  padding: 2rem 2.5rem;

  background: ${(props) => props.theme.colors.primary};
  border: 1px solid
    ${(props) => (props.active ? '#009d43' : props.theme.colors.primary)};
  border-radius: 1rem;

  img {
    width: 100%;
    max-width: 10rem;
    height: 100%;
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    span {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`
