import React, { useCallback, useEffect, useState } from 'react'
import { FiCopy, FiPlus } from 'react-icons/fi'

import { MenuProps, CategoryProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import Spinner from 'components/Spinner'
import CategoriesCard from './CategoriesCard'
import CategoryModal from './CategoryModal'
import CopyCategoryModal from './CopyCategoryModal'

import * as S from './styles'

const Category: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const lastActiveMenuId = localStorage.getItem('last-active-menu-id')
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenCopy, setIsOpenCopy] = useState(false)
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const [categorySelected, setCategorySelected] = useState<CategoryProps>(null)

  const loadMenusData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await api('user-api').get<MenuProps>(
        `menus/${lastActiveMenuId}`
      )

      setCategories(data.categories)
    } finally {
      setIsLoading(false)
    }
  }, [lastActiveMenuId])

  useEffect(() => {
    loadMenusData()
  }, [loadMenusData])

  const handleToggleModal = useCallback((category: CategoryProps = null) => {
    setIsOpen((prevState: boolean) => !prevState)
    setCategorySelected(category)
  }, [])

  const handleToggleCopyModal = useCallback(() => {
    setIsOpenCopy(!isOpenCopy)
  }, [isOpenCopy])

  const handleUpdateCategories = useCallback(
    async ({ value }) => {
      try {
        const { data } = await api('user-api').put(
          `menus/${lastActiveMenuId}`,
          {
            categories: value
          }
        )

        if (lastActiveMenuId === site.deliveryMenuId) {
          setSite({
            ...site,
            deliveryMenu: {
              ...site.deliveryMenu,
              categories: value
            }
          })
        } else if (lastActiveMenuId === site.takeoutMenuId) {
          setSite({
            ...site,
            takeoutMenu: {
              ...site.takeoutMenu,
              categories: value
            }
          })
        } else {
          setSite({
            ...site,
            indoorMenu: {
              ...site.indoorMenu,
              categories: value
            }
          })
        }

        showToast({
          message: 'Cardápio atualizado com sucesso!',
          type: 'success'
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      }
    },
    [lastActiveMenuId, setSite, showToast, site]
  )

  const NameMenu = useCallback(() => {
    if (lastActiveMenuId === site.deliveryMenuId) {
      return 'Entrega'
    }
    if (lastActiveMenuId === site.takeoutMenuId) {
      return 'Retirada'
    }

    return 'Mesa'
  }, [lastActiveMenuId, site])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <S.Container>
      <CategoryModal
        isOpen={isOpen}
        handleToggle={handleToggleModal}
        categories={categories}
        setCategories={setCategories}
        category={categorySelected}
        handleUpdateCategories={handleUpdateCategories}
      />

      <CopyCategoryModal
        isOpen={isOpenCopy}
        handleToggle={handleToggleCopyModal}
        categories={categories}
        setCategories={setCategories}
        handleUpdateCategories={handleUpdateCategories}
      />

      <S.Title>Cardápio - {NameMenu()}</S.Title>

      <S.Options>
        <S.ButtonAdd type="button" onClick={() => handleToggleModal()}>
          <FiPlus /> Adicionar categoria
        </S.ButtonAdd>

        <S.ButtonCopy type="button" onClick={handleToggleCopyModal}>
          <FiCopy /> Copiar de outro cardápio
        </S.ButtonCopy>
      </S.Options>

      <CategoriesCard
        categories={categories}
        setCategories={setCategories}
        handleToggle={handleToggleModal}
        handleUpdateCategories={handleUpdateCategories}
      />
    </S.Container>
  )
}

export default Category
