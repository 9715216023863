import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useModule } from 'contexts/ModuleContext'
import { useIFood } from 'contexts/IFoodContext'
import { getUserCode, getToken } from 'hooks/useIFood'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  integration: string
}

const schema = yup.object().shape({
  authorizationCode: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  authorizationCode: string
}

const IntegrationModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  integration
}) => {
  const { module } = useModule()
  const { ifood, setIFood, handleDiscconectIFood } = useIFood()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const integrationsInput = useMemo(
    () => ({
      ifood: {
        name: 'IFood',
        key: 'ifoodModule',
        image: '/img/icons/modules/integrations/ifood.svg'
      }
    }),
    []
  )
  const [isLoading, setIsLoading] = useState(false)
  const previewAuthorizationCode = watch('authorizationCode')

  const handleToggleModal = useCallback(() => {
    setIsLoading(false)
    setIFood({
      ...ifood,
      userCode: null
    })
    handleToggle()
  }, [handleToggle, ifood, setIFood])

  const handleGetUserCode = useCallback(async () => {
    const data = await getUserCode()

    window.open(data.verificationUrlComplete, '_blank')
    setIFood(data)
  }, [setIFood])

  const onSubmit = useCallback(
    async ({ authorizationCode }: FormDataProps) => {
      setIsLoading(true)

      try {
        const body = {
          authorizationCode,
          ...ifood
        }

        const data = await getToken(body, module.ifoodModule?.id)

        setIFood(data)
        handleToggle()
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
    },
    [handleToggle, ifood, module.ifoodModule?.id, setIFood]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={handleToggleModal}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          width: '100%',
          maxWidth: '500px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>Integração {integrationsInput[integration]?.name}</h1>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            {ifood?.accessToken ? (
              <S.Header>
                <h1>Sistema conectado.</h1>
              </S.Header>
            ) : ifood?.userCode ? (
              <S.Code>
                <S.Header>
                  <h1>
                    Digite aqui o código de ativação que você recebeu do ifood.
                  </h1>
                </S.Header>

                <S.InputCode
                  id="authorizationCode"
                  name="authorizationCode"
                  className={errors.authorizationCode && 'is-invalid'}
                  {...register('authorizationCode')}
                />
                {/* <S.Copy onClick={() => handleCopyCode(ifood?.userCode)}>
                   {ifood?.userCode}
                 </S.Copy> */}
              </S.Code>
            ) : (
              <S.Button type="button" cancel onClick={handleGetUserCode}>
                Conectar
              </S.Button>
            )}
          </S.Col>
          <S.Buttons>
            <S.Button type="button" cancel onClick={handleToggleModal}>
              Cancelar
            </S.Button>

            {!ifood?.accessToken && ifood?.userCode ? (
              <S.Button
                type="submit"
                disabled={
                  isLoading ||
                  previewAuthorizationCode === undefined ||
                  previewAuthorizationCode?.trim().length === 0
                }
              >
                Conectar
              </S.Button>
            ) : (
              <S.Button type="button" cancel onClick={handleDiscconectIFood}>
                Desconectar
              </S.Button>
            )}
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default IntegrationModal
