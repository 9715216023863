import styled from 'styled-components'
import media from 'styled-media-query'
import { ReactSortable } from 'react-sortablejs'
import Switch from 'react-switch'

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Content = styled(ReactSortable)`
  display: flex;
  /* gap: 2rem; */
  flex-direction: column;
`

export const Product = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 0.2px solid ${(props) => props.theme.colors.secondary};
  }
`

export const ProductLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  > div {
    gap: 10rem;
    align-items: center;
    justify-content: space-between;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  h1 {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const ProductRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  > div {
    gap: 10rem;
    align-items: center;
    justify-content: space-between;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const ProductStatus = styled(Switch)``

export const ProductSize = styled.div`
  display: flex;
  margin-right: 8.5rem;

  ${media.lessThan('medium')`
    display: none;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 25rem;

  height: 5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};
`
