import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img,
    div {
      width: 20rem;
      height: 20rem;
      object-fit: contain;

      padding: 1rem;

      background: ${(props) => props.theme.colors.primary};
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 1.5rem;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        width: 40px;
        height: 40px;

        color: #dddddd;
      }

      strong {
        font-size: 1.6rem;
        text-align: center;

        color: #dddddd;

        margin-top: 2rem;
      }
    }

    input {
      display: none;
    }
  }

  > button {
    font-size: 1.5rem;
    text-align: left;

    color: ${({ theme }) => theme.colors.text.cards};

    width: 100%;
    margin-top: 0.5rem;
  }
`
