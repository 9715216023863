import DeliveryAreas from 'pages/Dashboard/DeliveryAreas'
import Home from 'pages/Dashboard/Home'
import Indoor from 'pages/Dashboard/Indoor'
import Menu from 'pages/Dashboard/Menu'
import Category from 'pages/Dashboard/Menu/Category'

import Modules from 'pages/Dashboard/Modules'
import AnalyticsModule from 'pages/Dashboard/Modules/AnalyticsModule'
import BotModule from 'pages/Dashboard/Modules/BotModule'
import ColorsModule from 'pages/Dashboard/Modules/ColorsModule'
import IntegrationsModule from 'pages/Dashboard/Modules/IntegrationsModule'
import PrintersModule from 'pages/Dashboard/Modules/PrintersModule'

import MercadoPagoModule from 'pages/Dashboard/Modules/MercadoPagoModule'
import PagarmeModule from 'pages/Dashboard/Modules/PagarmeModule'
import PicPayModule from 'pages/Dashboard/Modules/PicPayModule'

import ClientForm from 'pages/Dashboard/ClientForm'
import OpeningHours from 'pages/Dashboard/OpeningHours'
import Orders from 'pages/Dashboard/Orders'
import PaymentMethods from 'pages/Dashboard/PaymentMethods'
import Profile from 'pages/Dashboard/Profile'
import Promotions from 'pages/Dashboard/Promotions'
import Stock from 'pages/Dashboard/Stock'

import TotemCategory from 'pages/TotemSelfService/Category'
import TotemHome from 'pages/TotemSelfService/Home'
import TotemProfile from 'pages/TotemSelfService/Profile'

import WhatsappModule from 'pages/Dashboard/Modules/WhatsappModule'
import Services from 'pages/Services/Home'

const serviceRoutes = [
  {
    path: '/',
    name: 'Profile',
    component: Services,
    layout: '/services'
  },
  {
    path: '/invoices/',
    name: 'Profile',
    component: Services,
    layout: '/services'
  }
]

const dashboardRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    layout: '/dashboard'
  },
  {
    path: '/orders/',
    name: 'Orders',
    component: Orders,
    layout: '/dashboard'
  },

  {
    path: '/menu/',
    name: 'Menu',
    component: Menu,
    layout: '/dashboard'
  },
  {
    path: '/menu/category/',
    name: 'Menu Category',
    component: Category,
    layout: '/dashboard'
  },

  {
    path: '/client-form/',
    name: 'Client',
    component: ClientForm,
    layout: '/dashboard'
  },

  {
    path: '/stock/',
    name: 'Stock',
    component: Stock,
    layout: '/dashboard'
  },

  {
    path: '/indoor/',
    name: 'Indoor',
    component: Indoor,
    layout: '/dashboard'
  },
  {
    path: '/opening-hours/',
    name: 'Opening Hours',
    component: OpeningHours,
    layout: '/dashboard'
  },
  {
    path: '/delivery-areas/',
    name: 'Delivery Areas',
    component: DeliveryAreas,
    layout: '/dashboard'
  },
  {
    path: '/payment-methods/',
    name: 'Payment Methods',
    component: PaymentMethods,
    layout: '/dashboard'
  },
  {
    path: '/promotions/',
    name: 'Promotions',
    component: Promotions,
    layout: '/dashboard'
  },

  {
    path: '/modules/',
    name: 'Modules',
    component: Modules,
    layout: '/dashboard'
  },

  {
    path: '/modules/colors/',
    name: 'Module Colors',
    component: ColorsModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/integrations/',
    name: 'Module Integrations',
    component: IntegrationsModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/bot/',
    name: 'Module Bot',
    component: BotModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/analytics/',
    name: 'Module Analytics',
    component: AnalyticsModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/printers/',
    name: 'Module Printers',
    component: PrintersModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/whatsapp/',
    name: 'Module Whatsapp',
    component: WhatsappModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/mercadopago/',
    name: 'Module MercadoPago',
    component: MercadoPagoModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/picpay/',
    name: 'Module PicPay',
    component: PicPayModule,
    layout: '/dashboard'
  },
  {
    path: '/modules/pagarme/',
    name: 'Module Pagarme',
    component: PagarmeModule,
    layout: '/dashboard'
  },

  {
    path: '/profile/',
    name: 'Profile',
    component: Profile,
    layout: '/dashboard'
  }
]

const totemRoutes = [
  {
    path: '/',
    name: 'Home',
    component: TotemHome,
    layout: '/totem'
  },
  {
    path: '/menu/',
    name: 'Menu',
    component: TotemCategory,
    layout: '/totem'
  },
  {
    path: '/profile/',
    name: 'Profile',
    component: TotemProfile,
    layout: '/totem'
  }
]

const menuRoutes = [...serviceRoutes, ...dashboardRoutes, ...totemRoutes]

export default menuRoutes
