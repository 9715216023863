import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 50vh;
`

export const Empty = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  div {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: bold;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    button {
      padding: 10px;

      background: ${(props) => props.theme.colors.blue};

      font-weight: bold;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Qr = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  img {
    padding: 20px;
    background: #fff;
  }

  div {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: bold;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    button {
      padding: 10px;

      width: 15rem;

      background: ${(props) => props.theme.colors.red};
      border-radius: 1rem;

      font-weight: bold;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  input,
  textarea,
  button {
    background: #f7fdff;

    padding: 1rem;

    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 5px;
  }
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`
