import styled, { css } from 'styled-components'

interface ContainerProps {
  mainBg?: boolean
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, mainBg }) => css`
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: 9.5rem;

    padding: 1.5rem;

    background: ${mainBg ? theme.colors.secondary : theme.colors.primary};
    border-radius: 1rem;

    img {
      width: 12rem;
      height: 12rem;
    }

    div {
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 500;
        font-size: 2rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }

      span {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }
    }
  `}
`
