import styled from 'styled-components'
import Mask from 'react-input-mask'
import InputCurrency from 'react-intl-currency-input'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`

export const Upload = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 5rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.primary};

  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 2px solid #cecece;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMask = styled(Mask)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.primary};

  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 2px solid #cecece;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.primary};

  background: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  border: 2px solid #cecece;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 2rem;
`

interface ButtonProps {
  cancel?: boolean
}

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.theme.colors.text.cards};
  background-color: ${(props) =>
    props.cancel ? props.theme.colors.red : props.theme.colors.green};
  border-color: ${(props) => props.theme.colors.green};

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  width: 12rem;

  font-weight: 600;
  text-align: center;

  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover:not(:disabled) {
    color: #f7fdff;
    opacity: 0.9;
  }
`
