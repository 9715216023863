import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { PaymentMethodProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: PaymentMethodProps | null): void
  payments: PaymentMethodProps[]
  setPaymentMethods(payments: PaymentMethodProps[]): void
  payment?: PaymentMethodProps
  handleUpdatePayment(payments: PaymentMethodProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const PaymentModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  payments,
  setPaymentMethods,
  payment,
  handleUpdatePayment
}) => {
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const [haveChangeOption, setHaveChangeOption] = useState(false)
  const [available, setAvailable] = useState(true)

  const handleResetStats = useCallback(() => {
    reset()
    setAvailable(true)
    setHaveChangeOption(false)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (payment) {
      setValue('name', payment.name, { shouldValidate: true })
      setHaveChangeOption(payment.haveChangeOption)
      setAvailable(payment.available)
    }
  }, [setValue, payment])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleHaveChangeOptionRadio = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target

    setHaveChangeOption(value === 'true')
  }

  const handleAvailableRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setAvailable(value === 'true')
  }

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const paymentData = {
        id: payment ? payment.id : uuidv4(),
        name: data.name.trim(),
        haveChangeOption,
        available
      }

      const updatedPayments = [...payments]

      if (payment) {
        const areaIndex = payments.findIndex((c) => c.id === payment.id)

        updatedPayments[areaIndex] = paymentData as PaymentMethodProps
      } else {
        updatedPayments.push(paymentData as PaymentMethodProps)
      }

      try {
        setPaymentMethods(updatedPayments)
        handleUpdatePayment(updatedPayments)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      payment,
      haveChangeOption,
      available,
      payments,
      setPaymentMethods,
      handleUpdatePayment,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo forma de pagamento'}
          </h1>
          <p>Detalhes da forma</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="name">Nome da forma</S.Label>
            <S.Input
              id="name"
              name="name"
              placeholder="Ex: Dinheiro"
              className={errors.name && 'is-invalid'}
              {...register('name')}
            />
          </S.Col>

          <S.Col>
            <S.Label>Opção de troco</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="haveChangeOption"
                  type="radio"
                  value="true"
                  defaultChecked={haveChangeOption}
                  onChange={handleHaveChangeOptionRadio}
                />
                <span>
                  <div>
                    <div>Disponível</div>
                    <div>
                      Permite que o cliente insira quanto de troco ele precisará
                    </div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="haveChangeOption"
                  type="radio"
                  value="false"
                  defaultChecked={!haveChangeOption}
                  onChange={handleHaveChangeOptionRadio}
                />
                <span>
                  <div>
                    <div>Indisponível</div>
                    <div>
                      Não permite que o cliente insira quanto de troco ele
                      precisará
                    </div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Col>
            <S.Label>Disponibilidade</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="true"
                  defaultChecked={available}
                  onChange={handleAvailableRadio}
                />
                <span>
                  <div>
                    <div>Disponível</div>
                    <div>
                      A forma ficará disponível para que o cliente possa
                      selecionar
                    </div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="false"
                  defaultChecked={!available}
                  onChange={handleAvailableRadio}
                />
                <span>
                  <div>
                    <div>Indisponível</div>
                    <div>
                      A forma ficará indisponível para que o cliente possa
                      selecionar
                    </div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default PaymentModal
