import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { useModule } from 'contexts/ModuleContext'
import { useIFood } from 'contexts/IFoodContext'

import IntegrationModal from './IntegrationModal'

import * as S from './styles'

interface Props extends RouteComponentProps {}

const IntegrationsModule: React.FC<Props> = () => {
  const { module } = useModule()
  const { ifood } = useIFood()

  const [isOpen, setIsOpen] = useState(false)
  const [integration, setIntegration] = useState(null)

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleSelectIntegration = useCallback((item) => {
    setIntegration(item)
    setIsOpen(true)
  }, [])

  return (
    <>
      <IntegrationModal
        isOpen={isOpen}
        handleToggle={handleToggle}
        integration={integration}
      />

      <S.Container>
        <S.Title>Integrações</S.Title>

        <S.Cards>
          <button
            type="button"
            onClick={() => handleSelectIntegration('ifood')}
          >
            <S.Card active={!!ifood?.accessToken}>
              <img
                src="/img/icons/modules/integrations/ifood.svg"
                alt="IFood"
              />
            </S.Card>
          </button>

          <button type="button">
            <S.Card active={false}>
              <img
                src="/img/icons/modules/integrations/aiqfome.png"
                alt="aiqfome"
              />
            </S.Card>
          </button>
        </S.Cards>
      </S.Container>
    </>
  )
}

export default IntegrationsModule
