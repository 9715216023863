import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  .predefined-dates {
    background-color: initial;
    cursor: pointer;
    padding: 1rem 2rem;
    color: ${(props) =>
      props.theme.name === 'dark'
        ? props.theme.colors.text.primary
        : props.theme.colors.primary};
    font-weight: 500;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: all 0.2s;
    margin-right: 12px;
    border: 2px solid transparent;
    opacity: 0.8;
  }

  .predefined-dates:not(:last-child):hover,
  .selected {
    border: 2px solid
      ${(props) =>
        props.theme.name === 'dark'
          ? props.theme.colors.text.primary
          : props.theme.colors.primary};
    opacity: 1;
  }

  #custom-datepicker:not(.custom-selected) {
    opacity: 0.8;
  }

  .DateRangePickerInput > div:not(:first-child) {
    display: none;
  }

  .DateRangePickerInput button {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    transition: all 0.2s;
  }

  .DateRangePickerInput button svg {
    stroke: ${(props) =>
      props.theme.name === 'dark'
        ? props.theme.colors.text.primary
        : props.theme.colors.primary};
  }

  .DateRangePickerInput {
    background: none;
  }
`
