import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import {
  CategoryProps,
  ItemProps,
  RecipeGroupProps,
  OptionGroupProps
} from 'utils/props'
import { api } from 'services/api'
import { useToast } from 'contexts/ToastContext'
import { useStock } from 'contexts/StockContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { taxableUnits } from 'utils/taxableUnits'

import ImageInput from './ImageInput'
import Price from './Price'
import Recipe from './Recipe'
import Options from './Options'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: ItemProps | null): void
  categories: CategoryProps[]
  setCategories(categories: CategoryProps[]): void
  category: CategoryProps
  product: ItemProps
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const schema = yup.object().shape({
  code: yup.string(),
  name: yup.string().required('Este campo é obrigatório'),
  description: yup.string(),
  posCode: yup.string()
})

interface FormDataProps {
  code: string
  name: string
  description: string
  posCode: string
}

const ProductModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  categories,
  setCategories,
  category,
  product,
  handleUpdateCategories
}) => {
  const { showToast } = useToast()
  const { stocks } = useStock()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const previewPosCode = watch('posCode')
  const [tabActive, setTabActive] = useState('DETAILS')
  const [image, setImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const [isDiscounted, setIsDiscounted] = useState(false)
  const [priceCostValue, setPriceCostValue] = useState(0)
  const [priceFromValue, setPriceFromValue] = useState<null | number>(null)
  const [priceOriginalValue, setPriceOriginalValue] = useState(0)
  const [priceDiscountValue, setPriceDiscountValue] = useState(0)
  const [priceShiftsValue, setPriceShiftsValue] = useState(null)
  const [prices, setPrices] = useState(
    category.sizes?.map((size) => ({
      sizeId: size.id,
      price: {
        currency: 'BRL',
        value: 0
      }
    }))
  )
  const [unit, setUnit] = useState<'UN' | 'KG' | 'G'>('UN')
  const [recipeGroup, setRecipeGroup] = useState<RecipeGroupProps[]>([])
  const [optionGroup, setOptionGroup] = useState<OptionGroupProps[]>([])
  const [availability, setAvailability] = useState('ALWAYS')

  useEffect(() => {
    if (product) {
      setValue('code', product?.code, { shouldValidate: true })
      setValue('name', product.name, { shouldValidate: true })
      if (product.description) {
        setValue('description', product.description, { shouldValidate: true })
      }
      if (product.posCode) {
        setValue('posCode', product.posCode, { shouldValidate: true })
      }

      if (category.type === 'ITEMS') {
        setPriceCostValue(product?.price?.costValue?.value || 0)
        if (product.price?.originalValue) setIsDiscounted(true)
        setPriceOriginalValue(
          product.price.originalValue
            ? product.price.originalValue.value
            : product.price.value.value
        )
        setPriceDiscountValue(
          product.price.originalValue && product.price.value.value
        )
        setPriceFromValue(product?.price?.priceFromValue?.value || null)
        setPriceShiftsValue(product?.price?.priceShiftsValue || null)
      } else {
        setPrices(
          category.sizes.map((size) => {
            const sizeInfo = product.prices.filter(
              (item) => item.sizeId === size.id
            )[0]

            const price = sizeInfo?.price?.value || 0

            console.log(size)

            return {
              sizeId: size.id,
              price: {
                currency: 'BRL',
                value: price
              }
            }
          })
        )
      }

      setImage(product.image)
      setPreviewImage(product.image)
      setUnit(product.unit)
      setRecipeGroup(product.recipeGroup || [])
      setOptionGroup(product.optionGroup)
    } else if (category) {
      setPrices(
        category.sizes?.map((size) => ({
          sizeId: size.id,
          price: {
            currency: 'BRL',
            value: 0
          }
        }))
      )
    }
  }, [category, product, setValue])

  const handleResetStats = useCallback(() => {
    reset()
    setOptionGroup([])
    setRecipeGroup([])
    setPrices([])
    setPriceDiscountValue(0)
    setPriceOriginalValue(0)
    setPriceCostValue(0)
    setIsDiscounted(false)
    setUnit('UN')
    setPreviewImage(null)
    setImage(null)
    setTabActive('DETAILS')
    setIsLoading(false)
  }, [reset])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleSelectTabActive = (tab: string) => {
    setTabActive(tab)
  }

  const handleDiscountActive = () => {
    setIsDiscounted((prevState: boolean) => !prevState)
  }

  const handleChangeTaxableUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as 'UN' | 'KG' | 'G'

    setUnit(value)
  }

  const handleChangePosCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    if (value === 'remove') {
      setValue('posCode', null, { shouldValidate: true })
      return
    }

    setValue('posCode', value, { shouldValidate: true })
  }

  const handleNextStep = () => {
    if (tabActive === 'DETAILS') {
      setTabActive('PRICE')
    } else if (tabActive === 'PRICE') {
      setTabActive('RECIPE')
    } else {
      setTabActive('OPTIONS')
    }
  }

  const SubmitButton = () => {
    if (
      (category.type !== 'PIZZAS' && tabActive !== 'OPTIONS') ||
      (category.type === 'PIZZAS' && tabActive !== 'RECIPE')
    ) {
      return (
        <S.Button
          onClick={handleNextStep}
          disabled={
            isLoading ||
            previewName === undefined ||
            previewName?.trim().length === 0
          }
        >
          Avançar
        </S.Button>
      )
    } else {
      return (
        <S.Button
          type="submit"
          disabled={
            isLoading ||
            previewName === undefined ||
            previewName?.trim().length === 0
          }
        >
          Salvar
        </S.Button>
      )
    }
  }

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const { name, code, description, posCode } = data
      let imageUrl = typeof image === 'string' ? image : null

      if (image && typeof image !== 'string') {
        const data = new FormData()
        data.append('file', image)

        const response = await api('user-api').post('files/images', data)

        const { url } = response.data
        imageUrl = url
      }

      const productData = {
        id: product ? product.id : uuidv4(),
        categoryId: category.id,
        icon: null,
        image: imageUrl,
        code,
        name,
        description: description.trim().length > 0 ? description : null,
        posCode: posCode && posCode.trim().length > 0 ? posCode : null,
        price: {
          costValue: {
            currency: 'BRL',
            value: priceCostValue
          },
          value: {
            currency: 'BRL',
            value: isDiscounted ? priceDiscountValue : priceOriginalValue
          },
          originalValue: isDiscounted
            ? {
                currency: 'BRL',
                value: priceOriginalValue
              }
            : null,
          priceFromValue: priceFromValue
            ? {
                currency: 'BRL',
                value: priceFromValue
              }
            : null,
          priceShiftsValue: priceShiftsValue || null
        },
        prices,
        recipeGroup,
        optionGroup,
        canDuplicate: true,
        canEdit: true,
        unit,
        shifts: {
          startTime: '00:00',
          endTime: '23:59',
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true
        },
        available: true,
        availability
      }

      const newCategories = [...categories]

      if (product) {
        const categoryIndex = categories.findIndex((c) => c.id === category.id)
        const productIndex = categories[categoryIndex].items.findIndex(
          (c) => c.id === product.id
        )

        Object.assign(newCategories[categoryIndex].items[productIndex], {
          ...productData
        })
      } else {
        const categoryIndex = categories.findIndex((c) => c.id === category.id)

        newCategories[categoryIndex].items.push(productData as ItemProps)
      }

      try {
        handleUpdateCategories({ value: newCategories })
        setCategories(newCategories)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      image,
      product,
      category.id,
      priceCostValue,
      isDiscounted,
      priceDiscountValue,
      priceOriginalValue,
      priceFromValue,
      priceShiftsValue,
      prices,
      recipeGroup,
      optionGroup,
      unit,
      availability,
      categories,
      handleUpdateCategories,
      setCategories,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <>
      <S.Container
        open={isOpen}
        showCloseIcon={false}
        onClose={() => handleToggleModal(null)}
        center
        styles={{
          modal: {
            padding: '2rem',
            background: '#293949',
            borderRadius: '1.5rem',
            minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
            minHeight: width <= 1000 ? `${height - 50}px` : '600px'
          }
        }}
      >
        <S.Content>
          <S.Header>
            <h1>
              {previewName && previewName.trim().length > 0
                ? previewName
                : 'Novo item'}
            </h1>

            <S.Options>
              <button
                type="button"
                className={tabActive === 'DETAILS' ? 'active' : ''}
                onClick={() => handleSelectTabActive('DETAILS')}
              >
                Detalhes
              </button>

              <button
                type="button"
                className={tabActive === 'PRICE' ? 'active' : ''}
                onClick={() => handleSelectTabActive('PRICE')}
              >
                Precificação
              </button>

              <button
                type="button"
                className={tabActive === 'RECIPE' ? 'active' : ''}
                onClick={() => handleSelectTabActive('RECIPE')}
              >
                Receita
              </button>

              {category.type !== 'PIZZAS' && (
                <button
                  type="button"
                  className={tabActive === 'OPTIONS' ? 'active' : ''}
                  onClick={() => handleSelectTabActive('OPTIONS')}
                >
                  Complementos
                </button>
              )}
            </S.Options>
          </S.Header>

          <S.Form onSubmit={handleSubmit(onSubmit)}>
            {tabActive === 'DETAILS' ? (
              <S.Table>
                <S.Col
                  style={{
                    gridArea: 'category'
                  }}
                >
                  <S.Label htmlFor="name">
                    Nome do produto (obrigatório)
                  </S.Label>
                  <S.Input
                    id="name"
                    name="name"
                    placeholder="Ex: Sorvete de morango"
                    className={errors.name && 'is-invalid'}
                    {...register('name')}
                  />
                </S.Col>

                <S.Col
                  style={{
                    gridArea: 'desc'
                  }}
                >
                  <S.Label htmlFor="description">Descrição</S.Label>
                  <S.TextArea
                    id="description"
                    name="description"
                    placeholder="Ex: Sorvete artesanal no tamanho desejados."
                    className={errors.description && 'is-invalid'}
                    {...register('description')}
                  />
                </S.Col>

                <S.Col
                  style={{
                    gridArea: 'img'
                  }}
                >
                  <ImageInput
                    setImage={setImage}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                  />
                </S.Col>

                <S.Col
                  style={{
                    gridArea: 'unit'
                  }}
                >
                  <S.Label htmlFor="unit">Unidade Tributável</S.Label>
                  <S.Select onChange={handleChangeTaxableUnit}>
                    {taxableUnits.map((taxableUnit) => (
                      <option
                        key={taxableUnit.unit}
                        selected={taxableUnit.unit === unit}
                        value={taxableUnit.unit}
                      >
                        {taxableUnit.unit} - {taxableUnit.name}
                      </option>
                    ))}
                  </S.Select>
                </S.Col>

                <S.ColWrapper
                  style={{
                    gridArea: 'stock'
                  }}
                >
                  <S.Col>
                    <S.Label htmlFor="code">Código Interno</S.Label>
                    <S.Input
                      id="code"
                      name="code"
                      placeholder="Ex: 1000"
                      className={errors.code && 'is-invalid'}
                      {...register('code')}
                    />
                  </S.Col>

                  <S.Col>
                    <S.Label htmlFor="posCode">Código PDV</S.Label>
                    <S.Select onChange={handleChangePosCode}>
                      {previewPosCode && previewPosCode.trim().length > 0 ? (
                        <option value="remove">Remover estoque</option>
                      ) : (
                        <option selected={!previewPosCode} disabled hidden>
                          Selecione um estoque
                        </option>
                      )}

                      {stocks
                        // .filter(stock =>
                        //   stock.methodsToAdd.some(methodToAdd => methodToAdd.type === unit)
                        // )
                        .map((stock) => (
                          <option
                            key={stock.id}
                            selected={stock.code === previewPosCode}
                            value={stock.code}
                          >
                            {stock.code} - {stock.name}
                          </option>
                        ))}
                    </S.Select>
                  </S.Col>
                </S.ColWrapper>
              </S.Table>
            ) : tabActive === 'PRICE' ? (
              <Price
                category={category}
                prices={prices}
                setPrices={setPrices}
                priceCostValue={priceCostValue}
                setPriceCostValue={setPriceCostValue}
                priceOriginalValue={priceOriginalValue}
                setPriceOriginalValue={setPriceOriginalValue}
                isDiscounted={isDiscounted}
                setIsDiscounted={setIsDiscounted}
                priceDiscountValue={priceDiscountValue}
                setPriceDiscountValue={setPriceDiscountValue}
                priceFromValue={priceFromValue}
                setPriceFromValue={setPriceFromValue}
                priceShiftsValue={priceShiftsValue}
                setPriceShiftsValue={setPriceShiftsValue}
              />
            ) : tabActive === 'RECIPE' ? (
              <Recipe
                optionGroup={recipeGroup}
                setOptionGroup={setRecipeGroup}
              />
            ) : (
              <Options
                optionGroup={optionGroup}
                setOptionGroup={setOptionGroup}
              />
            )}

            <S.Buttons>
              <S.Button
                type="button"
                cancel
                onClick={() => handleToggleModal(null)}
              >
                Cancelar
              </S.Button>

              <SubmitButton />
            </S.Buttons>
          </S.Form>
        </S.Content>
      </S.Container>
    </>
  )
}

export default ProductModal
