import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { api } from 'services/api'
import { useToast } from 'contexts/ToastContext'
import { useModule } from 'contexts/ModuleContext'

import * as S from './styles'

interface MessageProps {
  id: string
  title: string
  default: string
}

const WhatsappModule: React.FC = () => {
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const { module, setModule } = useModule()
  const [whatsappModule, setWhatsappModule] = useState(module.whatsappModule)

  const messages: MessageProps[] = [
    {
      id: 'orderPlaced',
      title: 'Mensagem que notifica que o pedido foi realizado com sucesso',
      default:
        'Seu pedido foi recebido com sucesso, vou te atualizando por aqui! ✅'
    },
    {
      id: 'orderConfirmed',
      title: 'Mensagem que notifica que o pedido foi confirmado',
      default:
        'Já estamos preparando o seu pedido! Avisaremos quando ele estiver pronto.'
    },
    {
      id: 'orderReadyDelivery',
      title: 'Mensagem que notifica que o pedido está pronto para entrega',
      default: 'Seu pedido está pronto e em breve sairá para entrega.'
    },
    {
      id: 'orderReadyTakeout',
      title: 'Mensagem que notifica que o pedido está pronto para retirada',
      default: '{username} seu pedido está pronto.'
    },
    {
      id: 'orderReadyIndoor',
      title:
        'Mensagem que notifica que o pedido está pronto para retirada na mesa',
      default: '{username} seu pedido está pronto.'
    },
    {
      id: 'orderDispatched',
      title: 'Mensagem que notifica que o pedido saiu para entrega',
      default: 'O seu pedido já saiu para entrega e logo chegará no seu local.'
    },
    {
      id: 'orderConcluded',
      title: 'Mensagem que notifica que o pedido foi entregue',
      default:
        '{username} seu pedido foi entregue, obrigado(a) pela preferência ❤️'
    },
    {
      id: 'orderPending',
      title: 'Mensagem que notifica que o pedido está pendente',
      default: '{username} seu pedido está pendente.'
    },
    {
      id: 'orderCancelled',
      title: 'Mensagem que notifica que o pedido foi cancelado',
      default: 'Seu pedido foi cancelado 😥​.'
    }
  ]

  const snippets = [
    {
      code: '{username}',
      description: 'Inserir nome do cliente'
    },
    {
      code: '{link}',
      description: 'Inserir link do cardápio'
    }
  ]

  const handleStatusMessage = (message: MessageProps) => {
    if (whatsappModule[message.id]) {
      setWhatsappModule((prevState) => ({
        ...prevState,
        [message.id]: null
      }))
    } else {
      setWhatsappModule((prevState) => ({
        ...prevState,
        [message.id]: message.default
      }))
    }
  }

  const handleChangeMessage = (messageId: string, value: string) => {
    if (value.trim().length <= 0) {
      setWhatsappModule((prevState) => ({
        ...prevState,
        [messageId]: null
      }))
    } else {
      setWhatsappModule((prevState) => ({
        ...prevState,
        [messageId]: value
      }))
    }
  }

  const handleSnippetMessage = (messageId: string, snippet: string) => {
    setWhatsappModule((prevState) => ({
      ...prevState,
      [messageId]: `${prevState[messageId]}${snippet}`
    }))
  }

  const handleSaveMessages = async () => {
    try {
      const { data } = await api('user-api').put(
        `modules/whatsapp/${module.whatsappModule.id}`,
        whatsappModule
      )

      setModule({
        ...module,
        whatsappModule: data
      })

      showToast({
        type: 'success',
        message: 'Módulo atualizado com sucesso!'
      })
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Aconteceu um erro!'
      })
    }
  }

  return (
    <S.Container>
      <S.Title>
        <h1>Whatsapp</h1>

        <S.Button primary onClick={handleSaveMessages}>
          Salvar
        </S.Button>
      </S.Title>

      <S.Content>
        {messages.map((message) => (
          <S.Message key={message.id}>
            <S.MessageHeader>
              <S.MessageHeaderLeft>
                <label htmlFor={message.id}>{message.title}</label>
              </S.MessageHeaderLeft>

              <S.MessageHeaderRight>
                <S.MessageStatus
                  onChange={() => handleStatusMessage(message)}
                  checked={!!whatsappModule[message.id]}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />
              </S.MessageHeaderRight>
            </S.MessageHeader>

            {whatsappModule[message.id] && (
              <>
                <S.MessageText
                  id={message.id}
                  value={whatsappModule[message.id]}
                  onChange={(e) =>
                    handleChangeMessage(message.id, e.currentTarget.value)
                  }
                >
                  {whatsappModule[message.id]}
                </S.MessageText>

                <S.Snippets>
                  {snippets.map((snippet) => (
                    <S.Button
                      key={snippet.code}
                      onClick={() =>
                        handleSnippetMessage(message.id, snippet.code)
                      }
                    >
                      {snippet.description}
                    </S.Button>
                  ))}
                </S.Snippets>
              </>
            )}
          </S.Message>
        ))}
      </S.Content>
    </S.Container>
  )
}

export default WhatsappModule
