import styled from 'styled-components'
import media from 'styled-media-query'
import InputCurrency from 'react-intl-currency-input'
import Switch from 'react-switch'

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;

  margin-bottom: 2rem;
`

export const Content = styled.div`
  display: grid;
  grid-template-areas:
    'sort unit posCode posCode delete'
    'sort name price price delete'
    'sort status status status status';
  gap: 2rem;

  ${media.lessThan('small')`
    grid-template-areas:
      "sort unit unit"
      "sort posCode posCode"
      "name name name"
      "price price price"
      "status status delete";
  `}

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 2rem;
      height: 2rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Status = styled(Switch)``
