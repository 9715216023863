import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Swal from 'sweetalert2'

import { api } from 'services/api'
import { useModule } from 'contexts/ModuleContext'
import { useWindowSize } from 'hooks/useWindowSize'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  analytics: string
}

const schema = yup.object().shape({
  pixel: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  pixel: string
}

const AnalyticsModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  analytics
}) => {
  const { module, setModule } = useModule()
  const [width] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const analyticsInput = useMemo(
    () => ({
      facebook: {
        name: 'Facebook',
        key: 'facebookPixelId',
        image: '/img/icons/modules/analytics/facebook.svg',
        exemple: 'Ex: 1234567890'
      },
      googleAnalytics: {
        name: 'Google Analytics',
        key: 'googleAnalyticsId',
        image: '/img/icons/modules/analytics/google-analytics.svg',
        exemple: 'Ex: UA-000000-00'
      }
    }),
    []
  )
  const [isLoading, setIsLoading] = useState(false)
  const previewPixel = watch('pixel')

  useEffect(() => {
    setValue('pixel', module.analyticsModule[analyticsInput[analytics]?.key], {
      shouldValidate: true
    })
  }, [analytics, analyticsInput, module, setValue])

  const handleRemovePixel = useCallback(() => {
    setIsLoading(true)

    try {
      Swal.fire({
        title: 'Isto irá excluir o pixel. Deseja continuar?',
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const analyticsData = {
            [analyticsInput[analytics]?.key]: null
          }

          const { data: response } = await api('user-api').put(
            `/modules/analytics/${module.analyticsModule.id}`,
            analyticsData
          )

          setModule({
            ...module,
            analyticsModule: response
          })
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      })
    } catch (err) {
      console.log('Aconteceu um error: ' + err)
    }
  }, [analytics, analyticsInput, module, setModule])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        const analyticsData = {
          [analyticsInput[analytics]?.key]: data.pixel
        }

        const { data: response } = await api('user-api').put(
          `/modules/analytics/${module.analyticsModule.id}`,
          analyticsData
        )

        setModule({
          ...module,
          analyticsModule: response
        })
        handleToggle()
      } catch (err) {
        console.log('Aconteceu um error: ' + err)
      } finally {
        setIsLoading(false)
      }
    },
    [analytics, analyticsInput, handleToggle, module, setModule]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={handleToggle}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>Pixel de Rastreamento</h1>

          <img
            src={analyticsInput[analytics]?.image}
            alt={analyticsInput[analytics]?.name}
          />
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="pixel">
              ID do Pixel do {analyticsInput[analytics]?.name}
            </S.Label>
            <S.Input
              id="pixel"
              name="pixel"
              placeholder={analyticsInput[analytics]?.exemple}
              className={errors.pixel && 'is-invalid'}
              {...register('pixel')}
            />

            {module.analyticsModule[analyticsInput[analytics]?.key] && (
              <S.RemovePixel type="button" onClick={handleRemovePixel}>
                Remover pixel
              </S.RemovePixel>
            )}
          </S.Col>
          <S.Buttons>
            <S.Button type="button" cancel onClick={handleToggle}>
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewPixel === undefined ||
                previewPixel?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default AnalyticsModal
