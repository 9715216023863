import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRouter'
import FirstTimeContext from 'contexts/FirstTimeContext'
import { useModule } from 'contexts/ModuleContext'
import { OrderProvider } from 'contexts/OrderContext'
import { useSite } from 'contexts/SiteContext'
import routes from 'routes/menuRoutes'
import { api } from 'services/api'
import { SiteProps } from 'utils/props'

import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Spinner from 'components/Spinner'
import ListServicesModal from './ListServicesModal'

import * as S from './styles'

const Dashboard = (props) => {
  const { site, setSite } = useSite()
  const { setModule } = useModule()

  const [isNewUser, setIsNewUser] = useState(false)
  const [serviceCreatedAt, setServiceCreatedAt] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [lastActiveServiceId, setLastActiveServiceId] = useState(
    localStorage.getItem('last-active-service-id')
  )

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true)
  }

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false)
  }

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState)
  }, [])

  const value = useMemo(
    () => ({ serviceCreatedAt, isNewUser }),
    [serviceCreatedAt, isNewUser]
  )

  const renderMenuRoutes = useMemo(
    () =>
      routes.map((prop) => {
        if (prop.layout.includes('/dashboard')) {
          return (
            <PrivateRoute
              {...props}
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={prop.name}
              exact
            />
          )
        }

        return null
      }),
    [props]
  )

  const loadServiceData = useCallback(async () => {
    setIsNewUser(false)
    setServiceCreatedAt('2020-12-14T01:52:25.973Z')
    moment.tz.setDefault('America/Bahia')
  }, [])

  const loadServices = useCallback(async () => {
    if (lastActiveServiceId) {
      setIsLoading(true)

      try {
        const { data } = await api('user-api').get<SiteProps>(
          `sites/${lastActiveServiceId}`
        )
        const { data: response } = await api('user-api').get(
          `modules/${data.serviceId}`
        )

        const { data: clientFormConfig } = await api('user-api').get(
          `services/${data.serviceId}/client-form`
        )

        setSite({ ...data, clientFormConfig })
        setModule(response)
      } finally {
        setIsLoading(false)
      }
    }
  }, [lastActiveServiceId, setModule, setSite])

  useEffect(() => {
    loadServices()
  }, [loadServices])

  useEffect(() => {
    loadServiceData()
  }, [loadServiceData])

  if (isLoading) {
    if (!lastActiveServiceId) {
      return (
        <ListServicesModal
          isOpen={!lastActiveServiceId}
          handleToggle={handleToggle}
          setLastActiveServiceId={setLastActiveServiceId}
        />
      )
    }

    return <Spinner />
  }

  return (
    <OrderProvider>
      <FirstTimeContext.Provider value={value}>
        <Sidebar
          logo={
            site?.logo
              ? site?.logo
              : `https://avatar.oxro.io/avatar?name=${site?.title}&background=1B242F`
          }
          title={site?.title}
          layout="dashboard"
          isSidebarOpen={isSidebarOpen}
          handleCloseSidebar={handleCloseSidebar}
        />

        <S.Container>
          <S.Layout>
            <Navbar
              service={lastActiveServiceId}
              handleOpenSidebar={handleOpenSidebar}
            />

            <S.Content>
              <Switch>{renderMenuRoutes}</Switch>
              {/* <Footer /> */}
            </S.Content>
          </S.Layout>
        </S.Container>
      </FirstTimeContext.Provider>
    </OrderProvider>
  )
}

export default Dashboard
