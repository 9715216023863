import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  ReactNode
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { UserProps } from 'utils/props'
import usePersistedState from 'hooks/usePersistedState'

interface UserProviderProps {
  children: ReactNode
}

interface UserContextData {
  loadUser(user: any): void
  logout(): void
  getToken(): string
  user: any
}

const UserContext = createContext({} as UserContextData)
export const TOKEN_KEY = process.env.REACT_APP_STAGE === 'qa' ? 'jwt' : 'twj'

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = usePersistedState('user', null)
  const history = useHistory()
  const location = useLocation()
  const publicURLs =
    location.pathname.includes('auth') || location.pathname.includes('prices')

  const loadUser = useCallback(
    (user: UserProps) => {
      setUserData(user)
    },
    [setUserData]
  )

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem('user')
    history.push('/auth/login/')
  }, [history])

  const getToken = () => localStorage.getItem(TOKEN_KEY)

  const user = useMemo(() => userData, [userData])

  useEffect(() => {
    if (!userData && !publicURLs) {
      logout()
    }
  }, [logout, userData, publicURLs])

  return (
    <UserContext.Provider
      value={{
        loadUser,
        logout,
        getToken,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useAuth() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useAuth must be used within a UserProvider')
  }

  return context
}

export { UserContext, UserProvider, useAuth }
