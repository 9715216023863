import styled, { css } from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  height: 100%;
`

export const Empty = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  ${({ theme }) => css`
    div {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 2rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }

      button {
        padding: 10px;

        width: 15rem;

        background: ${theme.colors.blue};

        font-weight: bold;
        font-size: 2rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }
    }
  `}
`

export const Qr = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  img {
    padding: 20px;
    background: #fff;
  }

  ${({ theme }) => css`
    div {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 2rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }

      button {
        padding: 10px;

        width: 15rem;

        background: ${theme.colors.red};
        border-radius: 1rem;

        font-weight: bold;
        font-size: 2rem;
        text-align: center;

        color: ${theme.colors.text.cards};
      }
    }
  `}
`

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  input,
  textarea,
  button {
    background: #f7fdff;

    padding: 1rem;

    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 5px;
  }
`
