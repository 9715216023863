import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border-radius: 0.5rem;

  padding: 1.2rem;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`
