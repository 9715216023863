/* eslint-disable react/display-name */
// @ts-nocheck
import React, {
  useState,
  useContext,
  memo,
  useCallback,
  useMemo,
  useEffect
} from 'react'
import { FiCalendar } from 'react-icons/fi'
import moment from 'moment-timezone'
import { parseISO } from 'date-fns'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION
} from 'react-dates/constants'

import { useSite } from 'contexts/SiteContext'
import { useWindowSize } from 'hooks/useWindowSize'
import FirstTimeContext from 'contexts/FirstTimeContext'
import PreDefinedDates from './PreDefinedDates'
import usePersistedState from 'hooks/usePersistedState'

import * as S from './styles'

interface DateProps {
  startDate: moment.Moment
  endDate: moment.Moment
}

interface Props extends DateProps {
  onDate({ startDate, endDate }: DateProps): void
  selectedDate: string
  setSelectedDate(selectedDate: string): void
}

const DatePicker: React.FC<Props> = ({
  onDate,
  startDate,
  endDate,
  selectedDate,
  setSelectedDate
}) => {
  const { site } = useSite()
  const [width] = useWindowSize()
  const [isCalendarTipOpen, setIsCalendarTipOpen] = usePersistedState(
    'isCalendarTipOpen',
    true
  )

  const [focusedInput, setFocusedInput] = useState(null)
  const [selectedStartDate, setSelectedStartDate] = useState(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isTipOpen, setIsTipOpen] = useState(false)

  const value = useContext(FirstTimeContext)

  const handlePopover = useCallback(
    () => setIsPopoverOpen(!isPopoverOpen),
    [isPopoverOpen]
  )

  const handleTip = useCallback(() => setIsTipOpen(true), [])

  const handlePredefinedDates = useCallback(
    (preStartDate, preEndDate) => {
      setSelectedStartDate(preStartDate)
      setSelectedEndDate(preEndDate)

      onDate({ startDate: preStartDate, endDate: preEndDate })
    },
    [onDate]
  )

  const handleSelectDate = useCallback(
    (date) => {
      setSelectedDate(date)

      if (date === 'today') {
        handlePredefinedDates(moment(), moment())
      }

      if (date === 'yesterday') {
        handlePredefinedDates(
          moment().subtract(1, 'days'),
          moment().subtract(1, 'day')
        )
      }

      if (date === 'seven') {
        handlePredefinedDates(moment().subtract(6, 'days'), moment())
      }

      if (date === 'thirty') {
        handlePredefinedDates(moment().subtract(29, 'days'), moment())
      }
    },
    [handlePredefinedDates, setSelectedDate]
  )

  const handlePreDefinedDate = useCallback(
    (preStartDate, preEndDate) => {
      setSelectedStartDate(preStartDate)
      setSelectedEndDate(preEndDate)

      onDate({ startDate: preStartDate, endDate: preEndDate })

      handleSelectDate('custom')
    },
    [onDate, handleSelectDate]
  )

  const handleUnderstoodTip = useCallback(
    () => setIsCalendarTipOpen(false),
    [setIsCalendarTipOpen]
  )

  const preDefinedDates = useMemo(
    () => ({
      calendarInfoPosition: 'after',
      renderCalendarInfo: () => (
        <PreDefinedDates handlePreDefinedDate={handlePreDefinedDate} />
      )
    }),
    [handlePreDefinedDate]
  )

  useEffect(() => {
    setIsTipOpen(true)
  }, [])

  return (
    <S.Container>
      <button
        type="button"
        className={`predefined-dates ${
          selectedDate === 'today' ? 'selected' : undefined
        }`}
        onClick={() => handleSelectDate('today')}
      >
        Hoje
      </button>
      <button
        type="button"
        className={`predefined-dates ${
          selectedDate === 'yesterday' ? 'selected' : undefined
        }`}
        onClick={() => handleSelectDate('yesterday')}
      >
        Ontem
      </button>
      <button
        type="button"
        className={`predefined-dates ${
          selectedDate === 'seven' ? 'selected' : undefined
        }`}
        onClick={() => handleSelectDate('seven')}
      >
        Últimos 7 Dias
      </button>
      <button
        type="button"
        className={`predefined-dates ${
          selectedDate === 'thirty' ? 'selected' : undefined
        }`}
        onClick={() => handleSelectDate('thirty')}
      >
        Últimos 30 Dias
      </button>

      <div
        id="custom-datepicker"
        className={selectedDate === 'custom' ? 'custom-selected' : undefined}
      >
        <DateRangePicker
          readOnly
          startDatePlaceholderText="Data inicio"
          startDateId="startDate"
          startDate={selectedStartDate}
          endDatePlaceholderText="Data final"
          endDate={selectedEndDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => {
            setSelectedStartDate(startDate)
            setSelectedEndDate(endDate)

            if (endDate && focusedInput === 'endDate') {
              onDate({ startDate, endDate })
              handleSelectDate('custom')
            }
          }}
          initialVisibleMonth={() =>
            moment().subtract(1, 'month').startOf('month')
          }
          focusedInput={focusedInput}
          onFocusChange={(onFocusChangeInput) =>
            setFocusedInput(onFocusChangeInput)
          }
          isOutsideRange={(date) =>
            date.isBefore(moment(parseISO(site.service.createdAt)), 'day') ||
            date.isAfter(moment(), 'day')
          }
          displayFormat="DD/MM/YYYY"
          orientation={
            width <= 727 ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
          }
          withPortal
          noBorder
          hideKeyboardShortcutsPanel
          minimumNights={0}
          customInputIcon={<FiCalendar size={20} />}
          {...preDefinedDates}
        />
      </div>
      {/* <Popover
        placement="top"
        isOpen={isPopoverOpen}
        target="custom-datepicker"
        toggle={handlePopover}
        trigger="hover"
        className="w-100"
      >
        <PopoverBody>
          {selectedDate === 'custom'
            ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(
                endDate
              ).format('DD/MM/YYYY')}`
            : 'Período Personalizado'}
        </PopoverBody>
      </Popover>

      {isCalendarTipOpen && (
        <Popover
          placement="top"
          isOpen={isTipOpen}
          target="custom-datepicker"
          toggle={handleTip}
          trigger="hover"
          className="w-100 custom-datepicker-tip"
        >
          <PopoverBody className="body">
            <span>Período Personalizado</span>
            <button type="button" onClick={handleUnderstoodTip}>
              Entendi
            </button>
          </PopoverBody>
        </Popover>
      )} */}
    </S.Container>
  )
}

export default memo(DatePicker)
