import styled from 'styled-components'
import media from 'styled-media-query'
import InputCurrency from 'react-intl-currency-input'
import ReactSwitch from 'react-switch'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`

export const Options = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  margin-bottom: 2rem;
`

export const Switch = styled(ReactSwitch)``

export const ColWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const TextArea = styled.textarea`
  resize: none;
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const PriceSizePrice = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  > h1 {
    font-weight: normal;
    font-size: 1.8rem;
  }

  > div {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    > div {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
      }
    }
  }
`

export const Price = styled.div`
  display: flex;
  gap: 2rem;
  align-items: flex-end;

  & + & {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
    }
  `}
`

export const ButtonDiscount = styled.button`
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.text.cards};

  width: auto;
  min-width: 195px;
  flex-grow: 1;
  padding: 16px 0;
`

export const ListDays = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  margin-top: 2rem;

  h1 {
    font-weight: 400;
    font-size: 2rem;
  }

  .day {
    display: flex;
    gap: 1rem;
    align-items: center;

    button {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;

      background: ${(props) => props.theme.colors.red};
      border-radius: 50%;

      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;

      color: #ffffff;

      &.available {
        background: ${(props) => props.theme.colors.green};
      }
    }
  }
`

export const ListDaysHelp = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  div + div {
    margin-top: 1rem;
  }

  div {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    p {
      width: 2rem;
      height: 2rem;

      background: ${(props) => props.theme.colors.red};
      border-radius: 50%;

      &.available {
        background: ${(props) => props.theme.colors.green};
      }
    }
  }
`

export const Days = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  margin-top: 2rem;
`

export const PriceDay = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    padding: 1rem;
  }
`
