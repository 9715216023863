import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* max-width: 800px; */
`

export const Options = styled.div`
  display: flex;
  align-items: left;

  width: 100%;

  margin-bottom: 2rem;

  button {
    font-weight: 500;
    font-size: 2rem;

    border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.text.cards};

    padding-bottom: 0.5rem;

    &.selected {
      border-bottom: 2px solid ${(props) => props.theme.colors.text.cards};
    }

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
`
