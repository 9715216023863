import React, { FormEvent, useCallback, useRef, useState } from 'react'

import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'

import ImageInput from './ImageInput'

import * as S from './styles'
import { useBot } from 'contexts/BotContext'
import { useToast } from 'contexts/ToastContext'

interface ResponseProps {
  result: string
  qrcode?: string
}

const SendMessage: React.FC = () => {
  const { site } = useSite()
  const { showToast } = useToast()

  const [numbers, setNumbers] = useState([
    {
      name: 'Guilherme Kayan',
      number: '73998172200',
      status: true
    }
  ])
  const [image, setImage] = useState(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const numberRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)

  const handleSendingMessages = useCallback(async () => {
    let err = 0
    let sucess = 0

    const promises = numbers
      .filter((number) => number.status)
      .map(async (item) => {
        // let data: ResponseProps

        // if (image) {
        //   const response = await api('bot-api').post<ResponseProps>(
        //     'send-image',
        //     {
        //       phone: `55${item.number}`.replace('@c.us', ''),
        //       message: messageRef.current.value,
        //       filename: 'MenuZer01',
        //       base64Data: image
        //     }
        //   )

        //   data = response.data
        // } else {
        const response = await api('bot-api').post('send-message', {
          phone: `55${item.number}`.replace('@c.us', ''),
          message: messageRef.current.value
        })

        // data = response.data
        // }

        // if (!data) {
        if (!response.data) {
          err += 1
        } else {
          sucess += 1
        }

        return response.data
        // return data
      })

    const results = await Promise.all(promises)

    showToast({
      message: `Erros: ${err}\nSucesso: ${sucess}`,
      type: 'dark'
    })
  }, [numbers, showToast, image])

  function cellNumberMask(value: string) {
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d{5})(\d)/, '$1-$2')

    return value
  }

  const handleCheckCellNumber = useCallback((index: number) => {
    setNumbers((prevState) =>
      prevState.map((item, i) => {
        if (i === index) {
          return { ...item, status: !item.status }
        }

        return item
      })
    )
  }, [])

  const dealWithCreatingNewNumber = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const name = nameRef.current.value
      const number = numberRef.current.value
      const findIndex = numbers.findIndex((n) => n.number === number)

      if (!name || !number || findIndex !== -1) return

      setNumbers((prevState) => [...prevState, { name, number, status: true }])

      nameRef.current.value = ''
      numberRef.current.value = ''
    },
    [numbers]
  )

  return (
    <S.Container>
      <S.Grid>
        <S.Card>
          <S.CardTitle>Contatos</S.CardTitle>

          <S.Contacts>
            {numbers.map((item, index) => (
              <div key={index}>
                <button
                  type="button"
                  className={item.status ? 'selected' : ''}
                  title={item.status ? 'Desativar' : 'Ativar'}
                  onClick={() => handleCheckCellNumber(index)}
                />
                <span>
                  {item.name} - {cellNumberMask(item.number)}
                </span>
              </div>
            ))}
          </S.Contacts>

          <S.Form onSubmit={dealWithCreatingNewNumber}>
            <S.Grid>
              <input type="text" ref={nameRef} placeholder="Nome do contato" />
              <input
                type="text"
                ref={numberRef}
                placeholder="Número do contato"
              />
              <button type="submit" />
            </S.Grid>
          </S.Form>
        </S.Card>

        <S.Card>
          <ImageInput setImage={setImage} />

          <textarea placeholder="Digite sua mensagem" ref={messageRef} />

          <S.Button type="button" onClick={handleSendingMessages}>
            Enviar mensagem
          </S.Button>
        </S.Card>
      </S.Grid>
    </S.Container>
  )
}

export default SendMessage
