export default {
  name: 'dark',
  grid: {
    container: '130rem',
    gutter: '3.2rem'
  },
  border: {
    radius: '0.4rem'
  },
  font: {
    family:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    bold: 600,
    sizes: {
      xxsmall: '1.4rem',
      xsmall: '1.6rem',
      small: '1.8rem',
      medium: '2.2rem',
      large: '2.6rem',
      xlarge: '3.4rem',
      xxlarge: '5.2rem'
    }
  },
  colors: {
    primary: '#293949',
    primaryHover: '#9466ff',
    secondary: '#1B242F',
    mainBg: '#1B242F',
    boxBg: '#FAFAFA',
    text: {
      primary: '#F7FDFF',
      cards: '#F7FDFF'
    },
    white: '#FFFFFF',
    black: '#000000',
    gray: '#CECECE',
    red: '#EA1D2C',
    green: '#50A773',
    blue: '#2D81D3',
    cards: '#293949',
    beige: '#F25757'
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '6.0rem',
    xlarge: '6.4rem',
    xxlarge: '12.8rem'
  }
}
