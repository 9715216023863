import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  p {
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`
