import styled, { keyframes } from 'styled-components'
import { Modal } from 'react-responsive-modal'
import media from 'styled-media-query'

const grow = keyframes`
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
`

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  ${media.greaterThan('medium')`
    max-width: 700px;
    min-width: 700px;
    width: 100%;
  `}

  > h1 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    color: #f7fdff;
  }

  .divLoading {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 2.5rem;
  }

  .spanLoading {
    width: 0.2em;
    height: 0.5em;
    background-color: white;
    margin: 5px 0px;
  }

  .spanLoading:nth-of-type(1) {
    animation: ${grow} 1s -0.45s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(2) {
    animation: ${grow} 1s -0.3s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(3) {
    animation: ${grow} 1s -0.15s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(4) {
    animation: ${grow} 1s ease-in-out infinite;
  }
`

export const Form = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Select = styled.select`
  z-index: 1;
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #f7fdff;
  background-color: #2d81d3;
  border-color: #2d81d3;

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  font-weight: 600;
  text-align: center;

  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: #f7fdff;
    background-color: #2d81d3;
    border-color: #2d81d3;
  }
`
