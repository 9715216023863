import React, { useState, useContext, useCallback } from 'react'
import { FaArrowsAlt } from 'react-icons/fa'
import { FiMoreVertical } from 'react-icons/fi'
import { v4 as uuidv4 } from 'uuid'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'

import { CategoryProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import ProductsCard from './ProductsCard'

import * as S from './styles'

interface Props {
  categories: CategoryProps[]
  setCategories(newState: CategoryProps[]): void
  handleToggle(item: CategoryProps | null): void
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const CategoriesCard: React.FC<Props> = ({
  categories,
  setCategories,
  handleToggle,
  handleUpdateCategories
}) => {
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [categorySelected, setCategorySelected] = useState<CategoryProps>(null)

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, category: CategoryProps) => {
      setCategorySelected(category)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleUpdateStatusCategory = useCallback(
    ({ id }) => {
      const newState = categories.map((category) => {
        if (category.id === id) {
          return { ...category, available: !category.available }
        }

        return category
      })

      handleUpdateCategories({ value: newState })
      setCategories(newState)
    },
    [categories, handleUpdateCategories, setCategories]
  )

  const handleModalCategoryEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggle(categorySelected)
  }, [categorySelected, handleMenuOptionsClose, handleToggle])

  const handleCategoryDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir a categoria "${categorySelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const categoryIndex = categories.findIndex(
            (c) => c.id === categorySelected.id
          )

          const updatedCategories = [...categories]
          const filteredCategories = updatedCategories.filter(
            (_, i) => i !== categoryIndex
          )

          handleUpdateCategories({ value: filteredCategories })
          setCategories(filteredCategories)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    categories,
    categorySelected,
    handleMenuOptionsClose,
    handleUpdateCategories,
    setCategories,
    showToast
  ])

  const handleCategoryCopy = useCallback(() => {
    try {
      Swal.fire({
        title: `Tem certeza que deseja duplicar categoria "${categorySelected.name}"`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const newCategories = [
            ...categories,
            {
              ...categorySelected,
              id: uuidv4()
            }
          ]

          handleUpdateCategories({ value: newCategories })
          setCategories(newCategories)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    categorySelected,
    categories,
    handleUpdateCategories,
    setCategories,
    showToast
  ])

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalCategoryEdit}>
          Editar
        </StyledMenuItem>
        <StyledMenuItem onClick={handleCategoryDelete}>Excluir</StyledMenuItem>
        {/* <StyledMenuItem
          onClick={handleCategoryCopy}
        >
          Duplicar
        </StyledMenuItem> */}
      </StyledMenu>

      <S.Container
        handle=".handle"
        animation={150}
        list={categories}
        onEnd={() =>
          handleUpdateCategories({
            value: categories
          })
        }
        setList={(newState) => setCategories(newState)}
      >
        {categories.map((category) => (
          <S.Category key={category.id}>
            <S.CategoryHeader>
              <S.CategoryHeaderLeft>
                <button type="button" className="handle">
                  <FaArrowsAlt />
                </button>

                <h1>{category.name}</h1>
              </S.CategoryHeaderLeft>

              <S.CategoryHeaderRight>
                <S.CategoryStatus
                  onChange={() =>
                    handleUpdateStatusCategory({
                      id: category.id,
                      value: !category.available
                    })
                  }
                  checked={category.available}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />

                <button
                  type="button"
                  title="Opções"
                  onClick={(e) => handleMenuOptionsOpen(e, category)}
                >
                  <FiMoreVertical />
                </button>
              </S.CategoryHeaderRight>
            </S.CategoryHeader>

            <S.CategoryTitle>
              <S.CategoryTitleLeft>
                <h1>Item</h1>
              </S.CategoryTitleLeft>

              <S.CategoryTitleRight>
                {category.type !== 'ITEMS' && <h1>Tamanho</h1>}
                <h1>Status</h1>
              </S.CategoryTitleRight>
            </S.CategoryTitle>

            <ProductsCard
              category={category}
              products={category.items}
              categories={categories}
              setCategories={setCategories}
              handleUpdateCategories={handleUpdateCategories}
            />
          </S.Category>
        ))}
      </S.Container>
    </>
  )
}

export default CategoriesCard
