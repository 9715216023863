import React from 'react'
import Lottie from 'react-lottie'

import animationData from './loading.json'

const Spinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  }

  return (
    <Lottie
      isClickToPauseDisabled
      options={defaultOptions}
      height="30rem"
      width="30rem"
      speed={3}
    />
  )
}

export default Spinner
