import React, { useState, useMemo } from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRouter'
import routes from 'routes/menuRoutes'

import Sidebar from 'components/Sidebar'
import Navbar from './Navbar'

import * as S from './styles'

const Services = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true)
  }

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false)
  }

  const renderMenuRoutes = useMemo(
    () =>
      routes.map((prop) => {
        if (prop.layout.includes('/services')) {
          return (
            <PrivateRoute
              {...props}
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={prop.name}
              exact
            />
          )
        }

        return null
      }),
    [props]
  )

  return (
    <>
      <Sidebar
        layout="services"
        isSidebarOpen={isSidebarOpen}
        handleCloseSidebar={handleCloseSidebar}
      />

      <S.Container>
        <S.Layout>
          <Navbar handleOpenSidebar={handleOpenSidebar} />

          <S.Content>
            <Switch>{renderMenuRoutes}</Switch>
          </S.Content>
        </S.Layout>
      </S.Container>
    </>
  )
}

export default Services
