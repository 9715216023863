import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'
import { states } from 'utils/utils'

import * as S from './styles'

const schema = yup.object().shape({
  cep: yup.string().required('Este campo é obrigatório'),
  state: yup.string().min(2).uppercase().required('Este campo é obrigatório'),
  city: yup.string().required('Este campo é obrigatório'),
  streetName: yup.string().required('Este campo é obrigatório'),
  streetNumber: yup.string().required('Este campo é obrigatório'),
  complement: yup.string().required('Este campo é obrigatório'),
  neighborhood: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  cep: string
  state: string
  city: string
  streetName: string
  streetNumber: string
  complement: string
  neighborhood: string
}

const Address: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const state = watch('state')

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setValue('cep', site.address?.cep, { shouldValidate: true })
    setValue('state', site.address?.state, { shouldValidate: true })
    setValue('city', site.address?.city, { shouldValidate: true })
    setValue('streetName', site.address?.streetName, { shouldValidate: true })
    setValue('streetNumber', site.address?.streetNumber, {
      shouldValidate: true
    })
    setValue('complement', site.address?.complement, { shouldValidate: true })
    setValue('neighborhood', site.address?.neighborhood, {
      shouldValidate: true
    })
  }, [setValue, site])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        const { data: response } = await api('user-api').put(
          `sites/${site.id}/address`,
          {
            address: data
          }
        )

        setSite({
          ...site,
          address: data
        })

        showToast({
          message: 'Endereço atualizado com sucesso!',
          type: 'success'
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ type: 'error', message })
      } finally {
        setIsLoading(false)
      }
    },
    [setSite, showToast, site]
  )

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Col>
        <S.Label htmlFor="cep">CEP</S.Label>
        <S.Input
          id="cep"
          name="cep"
          placeholder="Ex: 45995-021"
          maxLength={12}
          className={errors.cep && 'is-invalid'}
          {...register('cep')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="state">Estado</S.Label>

        <S.Select
          id="state"
          className={errors.state && 'is-invalid'}
          onChange={(e) => setValue('state', e.target.value)}
        >
          {states.map((item) => (
            <option key={item} value={item} selected={item === state}>
              {item}
            </option>
          ))}
        </S.Select>
      </S.Col>

      <S.Col>
        <S.Label htmlFor="city">Cidade</S.Label>
        <S.Input
          id="city"
          name="city"
          placeholder="Ex: Teixeira de Freitas"
          className={errors.city && 'is-invalid'}
          {...register('city')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="streetName">Logradouro</S.Label>
        <S.Input
          id="streetName"
          name="streetName"
          placeholder="Ex: Rua Fulano de tal"
          className={errors.streetName && 'is-invalid'}
          {...register('streetName')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="streetNumber">Número</S.Label>
        <S.Input
          id="streetNumber"
          name="streetNumber"
          placeholder="Ex: 100"
          className={errors.streetNumber && 'is-invalid'}
          {...register('streetNumber')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="complement">Complemento</S.Label>
        <S.Input
          id="complement"
          name="complement"
          placeholder="Ex: Próximo ao mercado de fulano de tal"
          className={errors.complement && 'is-invalid'}
          {...register('complement')}
        />
      </S.Col>

      <S.Col>
        <S.Label htmlFor="neighborhood">Bairro</S.Label>
        <S.Input
          id="neighborhood"
          name="neighborhood"
          placeholder="Ex: Bairro"
          className={errors.neighborhood && 'is-invalid'}
          {...register('neighborhood')}
        />
      </S.Col>

      <S.Buttons>
        <S.Button disabled={isLoading}>Salvar</S.Button>
      </S.Buttons>
    </S.Container>
  )
}

export default Address
