import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'

import { CouponProps } from 'utils/props'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'

import Spinner from 'components/Spinner'
import PromotionModal from './PromotionModal'

import * as S from './styles'

const Promotions: React.FC = () => {
  const { site } = useSite()
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isCouponsModalOpen, setIsCouponsModalOpen] = useState(false)
  const [coupons, setCoupons] = useState<CouponProps[]>([])
  const [couponSelected, setCouponSelected] = useState<CouponProps>(null)

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const loadCouponsData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await api('user-api').get(
        `coupons/${site.service.id}/list`
      )

      setCoupons(data)
    } finally {
      setIsLoading(false)
    }
  }, [site])

  useEffect(() => {
    loadCouponsData()
  }, [loadCouponsData])

  const handleUpdateCoupons = useCallback(
    async (id, coupon: CouponProps) => {
      try {
        const { data } = await api('user-api').put(`coupons/${id}`, coupon)

        setCoupons((prevState) =>
          prevState.map((coupon) => (coupon.id === id ? data : coupon))
        )

        showToast({
          type: 'success',
          message: 'Cupom atualizado com sucesso!'
        })
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Aconteceu um erro!'
        })
      }
    },
    [showToast]
  )

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, stock: CouponProps) => {
      setCouponSelected(stock)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleCouponsModal = useCallback((stock: CouponProps = null) => {
    setCouponSelected(stock)
    setIsCouponsModalOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalCouponsEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleCouponsModal(couponSelected)
  }, [handleMenuOptionsClose, handleToggleCouponsModal, couponSelected])

  const handleCouponsDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o cupom "${couponSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const updatedCouponss = [...coupons]
          const filteredCouponss = updatedCouponss.filter(
            (item) => item.id !== couponSelected.id
          )

          try {
            setCoupons(filteredCouponss)

            await api('user-api').delete(`coupons/${couponSelected.id}`)
          } catch (err) {
            const message =
              err?.response?.data?.error ||
              'Por Favor entrar em contato com suporte.'

            showToast({ message, type: 'error' })
          }
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [handleMenuOptionsClose, couponSelected, coupons, showToast])

  const handleCouponsStatusUpdate = useCallback(
    ({ id }) => {
      const updatedCouponss = coupons.map((item) => {
        if (item.id === id) {
          handleUpdateCoupons(id, { ...item, active: !item.active })
          return { ...item, available: !item.active }
        }

        return item
      })

      setCoupons(updatedCouponss)
    },
    [handleUpdateCoupons, coupons]
  )

  const handleCopyCode = useCallback(
    (code: string) => {
      const input = document.body.appendChild(document.createElement('input'))
      input.value = code
      input.focus()
      input.select()
      document.execCommand('copy')
      input.parentNode.removeChild(input)

      showToast({
        message: 'Código do cupom copiado com sucesso!',
        type: 'success'
      })
    },
    [showToast]
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalCouponsEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleCouponsDelete}>Excluir</StyledMenuItem>
      </StyledMenu>

      <PromotionModal
        isOpen={isCouponsModalOpen}
        handleToggle={handleToggleCouponsModal}
        coupon={couponSelected}
        coupons={coupons}
        setCoupons={setCoupons}
      />

      <S.Container>
        <S.Title>Cupons</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleCouponsModal()}>
            <FiPlus /> Adicionar cupom
          </S.ButtonAdd>
        </S.Options>

        <S.Promotions>
          {coupons.map((item, index) => (
            <S.Promotion key={item.id}>
              <S.CouponsHeader>
                <S.CouponsHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.CouponsHeaderLeft>

                <S.CouponsHeaderRight>
                  <S.CouponsStatus
                    onChange={() => handleCouponsStatusUpdate({ id: item.id })}
                    checked={item.active}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.CouponsHeaderRight>
              </S.CouponsHeader>

              <S.Form>
                <S.Col>
                  <S.Label htmlFor={`code_${index}`}>Código</S.Label>

                  <S.Copy
                    id={`code_${index}`}
                    onClick={() => handleCopyCode(item.name)}
                  >
                    {item.name}
                  </S.Copy>
                </S.Col>

                <S.Col>
                  <S.Label htmlFor={`quantityInCoupons_${index}`}>
                    Quantidade de uso
                  </S.Label>

                  <S.Input
                    id={`quantityInCoupons_${index}`}
                    value={item.numberOfUses}
                    disabled
                  />
                </S.Col>
              </S.Form>
            </S.Promotion>
          ))}
        </S.Promotions>
      </S.Container>
    </>
  )
}

export default Promotions
