import React, { useState, useContext, useCallback, useEffect } from 'react'
import { FaArrowsAlt } from 'react-icons/fa'
import { ThemeContext } from 'styled-components'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Swal from 'sweetalert2'

import { CategoryProps, ItemProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'

import ProductModal from './ProductModal'
import ModalCopyProduct from './ModalCopyProduct'

import * as S from './styles'

interface Props {
  categories: CategoryProps[]
  setCategories(newState: CategoryProps[]): void
  products: ItemProps[]
  category: CategoryProps
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const ProductsCard: React.FC<Props> = ({
  categories,
  setCategories,
  products,
  category,
  handleUpdateCategories
}) => {
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [items, setItems] = useState(products)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [productSelected, setProductSelected] = useState<ItemProps>(null)

  const [isOpenModalProduct, setIsOpenModalProduct] = useState(false)
  const [isOpenModalProductCopy, setIsOpenModalProductCopy] = useState(false)

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, product: ItemProps) => {
      setProductSelected(product)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    setItems(category.items)
  }, [category])

  const handleToggleModal = useCallback((product: ItemProps = null) => {
    setProductSelected(product)
    setIsOpenModalProduct((prevState: boolean) => !prevState)
  }, [])

  const handleUpdateProductOrder = useCallback(() => {
    const newState = categories.map((item) => {
      if (item.id === category.id) {
        return { ...item, items }
      }

      return item
    })

    handleUpdateCategories({ value: newState })
    setCategories(newState)
  }, [categories, category.id, handleUpdateCategories, items, setCategories])

  const handleUpdateStatusProduct = useCallback(
    ({ id }) => {
      const newStateProduct = items.map((item) => {
        if (item.id === id) {
          return { ...item, available: !item.available }
        }

        return item
      })

      const newState = categories.map((item) => {
        if (item.id === category.id) {
          return { ...item, items: newStateProduct }
        }

        return item
      })

      handleUpdateCategories({ value: newState })
      setCategories(newState)
      setItems(newStateProduct)
    },
    [categories, category.id, handleUpdateCategories, items, setCategories]
  )

  const handleModalProductEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(productSelected)
  }, [handleMenuOptionsClose, handleToggleModal, productSelected])

  const handleProductDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o produto "${productSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const categoryIndex = categories.findIndex(
            (c) => c.id === productSelected.categoryId
          )

          const newCategories = [...categories]

          newCategories[categoryIndex] = {
            ...newCategories[categoryIndex],
            items: newCategories[categoryIndex].items.filter(
              (item) => item.id !== productSelected.id
            )
          }

          handleUpdateCategories({ value: newCategories })
          setCategories(newCategories)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    handleMenuOptionsClose,
    productSelected,
    categories,
    handleUpdateCategories,
    setCategories,
    showToast
  ])

  const handleModalProductCopy = () => {
    handleMenuOptionsClose()
    setIsOpenModalProductCopy(true)
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalProductEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleProductDelete}>Excluir</StyledMenuItem>
        <StyledMenuItem onClick={handleModalProductCopy}>
          Duplicar
        </StyledMenuItem>
      </StyledMenu>

      <ProductModal
        isOpen={isOpenModalProduct}
        handleToggle={handleToggleModal}
        categories={categories}
        setCategories={setCategories}
        category={category}
        product={productSelected}
        handleUpdateCategories={handleUpdateCategories}
      />

      <ModalCopyProduct
        isOpen={isOpenModalProductCopy}
        handleToggle={() => setIsOpenModalProductCopy(false)}
        categories={categories}
        setCategories={setCategories}
        product={productSelected}
        setProduct={setProductSelected}
        handleUpdateCategories={handleUpdateCategories}
      />

      <S.Container>
        <S.Content
          handle=".handle"
          animation={150}
          list={items}
          onEnd={handleUpdateProductOrder}
          setList={(newState) => setItems(newState)}
        >
          {items.map((product) => (
            <S.Product key={product.id}>
              <S.ProductLeft>
                <button type="button" className="handle">
                  <FaArrowsAlt />
                </button>

                <div>
                  <h1>{product.name}</h1>
                </div>
              </S.ProductLeft>

              <S.ProductRight>
                {category.type !== 'ITEMS' && (
                  <S.ProductSize>3 tamanhos</S.ProductSize>
                )}

                <S.ProductStatus
                  onChange={() =>
                    handleUpdateStatusProduct({
                      id: product.id
                    })
                  }
                  checked={product.available}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />

                <button
                  type="button"
                  title="Opções"
                  onClick={(e) => handleMenuOptionsOpen(e, product)}
                >
                  <FiMoreVertical />
                </button>
              </S.ProductRight>
            </S.Product>
          ))}
        </S.Content>

        <S.ButtonAdd type="button" onClick={() => handleToggleModal(null)}>
          <FiPlus /> Adicionar produto
        </S.ButtonAdd>
      </S.Container>
    </>
  )
}

export default ProductsCard
