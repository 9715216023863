import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { isAuthenticated } from 'services/auth'

const tokenAuthName = process.env.REACT_APP_STAGE === 'prod' ? 'jwt' : 'twj'

interface Props {
  component: React.ComponentClass
  owner?: boolean
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  owner,
  ...rest
}) => {
  const tokenAuthentication = localStorage.getItem(tokenAuthName)

  useEffect(() => {
    if (!isAuthenticated(owner)) {
      if (!tokenAuthentication) {
        localStorage.setItem('user', 'null')
      }
    }
  }, [owner, tokenAuthentication])

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated(owner) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
