import styled, { css } from 'styled-components'
import { Modal } from 'react-responsive-modal'
import media from 'styled-media-query'
import InputCurrency from 'react-intl-currency-input'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${({ theme }) => css`
    h1 {
      font-weight: bold;
      font-size: 2rem;

      color: ${theme.colors.text.cards};
    }

    p {
      font-size: 1.8rem;

      color: ${theme.colors.text.cards};
    }
  `}
`

export const Form = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  > label {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    input {
      width: 1.5rem;
      height: 1.5rem;

      flex-shrink: 0;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
`

interface ButtonProps {
  cancel?: boolean
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, cancel }) => css`
    color: ${theme.colors.text.cards};
    background-color: ${cancel ? theme.colors.red : theme.colors.green};
    border-color: ${theme.colors.green};

    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

    min-width: 12rem;

    font-weight: 600;
    text-align: center;

    border: 1px solid transparent;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    border-radius: 0.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover:not(:disabled) {
      color: #f7fdff;
      opacity: 0.9;
    }
  `}
`
