import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Login from './Login'

const Auth = (props: any) => (
  <BrowserRouter>
    <Switch>
      <Route path="/auth/login" component={() => <Login {...props} />} />
    </Switch>
  </BrowserRouter>
)

export default Auth
