import socketio from 'socket.io-client'

// const baseURL = 'http://10.0.0.108:3338'
const baseURL = 'https://api.menuzer01.com'
// const baseURL = 'http://localhost:3333'
// export const baseURL = 'https://test-api.menuzer01.com'

// export const baseURL = process.env.BASE_URL

const socket = socketio(baseURL, {
  transports: ['websocket']
})

// const botSocket = socketio('http://10.0.0.107:21465', {
//   transports: ['websocket']
// })

// const botSocket = socketio('https://api.whatsapp.zer01.info', {
//   transports: ['websocket']
// })

export {
  socket
  // botSocket
}
