import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

import * as S from './styles'

interface Props {
  categoriesTypes: {
    type: string
    image: string
    title: string
    subtitle: string
  }[]
  handleSelectCategoryType(type: string): void
  categoryType: string
}

const SelectCategoryTypeCard: React.FC<Props> = ({
  categoriesTypes,
  handleSelectCategoryType,
  categoryType
}) => (
  <S.Container>
    {categoriesTypes.map((category) => (
      <S.Card
        key={category.type}
        className={`${
          categoryType === category.type && 'selected-category-card'
        }`}
        onClick={() => handleSelectCategoryType(category.type)}
      >
        {categoryType === category.type && <FaCheckCircle size={20} />}
        <img src={category.image} alt={category.type} />

        <div>
          <h1>{category.title}</h1>
          <p>{category.subtitle}</p>
        </div>
      </S.Card>
    ))}
  </S.Container>
)

export default SelectCategoryTypeCard
