import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 0;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    padding: 14px 46px;
    color: ${(props) => props.theme.colors.primary};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
    cursor: pointer;

    &:hover {
      color: ${(props) =>
        props.theme.name === 'dark'
          ? props.theme.colors.secondary
          : props.theme.colors.text.primary};
      background-color: ${(props) =>
        props.theme.name === 'dark'
          ? props.theme.colors.text.cards
          : props.theme.colors.primary};
    }
  }
`
