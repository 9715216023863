import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Table = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background: ${(props) => props.theme.colors.primary};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;

  padding: 1.5rem;
`

export const TableHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3rem;
    height: 3rem;

    border-radius: 2rem;
    border: 2px solid ${(props) => props.theme.colors.text.cards};

    span {
      font-weight: bold;
      font-size: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Orders = styled.main`
  display: flex;
  flex-direction: column;

  gap: 10px;
`

export const Order = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.secondary};
  border-radius: 0.5rem;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));

  padding: 1.5rem;
`

export const Left = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: space-between;

  width: 80%;
  height: 100%;
`

export const Right = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  img {
    width: 3rem;
    height: 3rem;
  }
`

export const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;

  border-radius: 2rem;
  border: 2px solid ${(props) => props.theme.colors.text.cards};

  opacity: 0.8;

  span {
    font-weight: bold;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const OrderHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h1 {
    font-weight: bold;
    font-size: 1.6rem;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.text.cards};
  }

  span {
    font-weight: bold;
    font-size: 1.2rem;

    color: ${(props) => props.theme.colors.text.cards};

    opacity: 0.8;
  }
`

export const OrderInformation = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
    }

    span {
      font-weight: bold;
      font-size: 1.3rem;
      text-align: start;

      color: ${(props) => props.theme.colors.text.cards};

      opacity: 0.8;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    background: #5563ac;
    border-radius: 0.5rem;

    font-size: 1.5rem;
    color: #ffffff;

    width: 100%;
    height: 3rem;
    max-height: 3rem;
    padding: 0.5rem 1rem;
  }

  button:not(:first-child) {
    margin-top: 1rem;
  }
`

export const Footer = styled.footer`
  display: flex;
  gap: 1rem;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: start;

    color: ${(props) => props.theme.colors.text.cards};

    opacity: 0.8;
  }
`
