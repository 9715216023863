import React from 'react'
import Lottie from 'react-lottie'

import { useBot } from 'contexts/BotContext'
import animationData from './loading.json'

import * as S from './styles'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData
}

const BotStatus: React.FC = () => {
  const { isLoading, qrCode, handleCreateTheBot, handleCloseTheBot } = useBot()

  return (
    <S.Container>
      {qrCode ? (
        <S.Container>
          <S.Qr>
            <img src={qrCode} alt="Qr" draggable={false} />

            <div>
              <h1>Leia o código Qr acima para se conectar</h1>

              <button type="button" onClick={handleCloseTheBot}>
                Cancelar
              </button>
            </div>
          </S.Qr>
        </S.Container>
      ) : (
        <S.Empty>
          <img src="/favicon.png" alt="Açaí Comandante Bot" width={180} />

          <div>
            <h1>Nenhum robô ativo no momento</h1>

            {isLoading ? (
              <Lottie
                isClickToPauseDisabled
                options={defaultOptions}
                height={100}
                speed={1}
              />
            ) : (
              <button type="button" onClick={handleCreateTheBot}>
                Criar
              </button>
            )}
          </div>
        </S.Empty>
      )}
    </S.Container>
  )
}

export default BotStatus
