import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ThemeContext } from 'styled-components'

import { api } from 'services/api'
import { useModule } from 'contexts/ModuleContext'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

const schema = yup.object().shape({
  apiKey: yup.string().required('A api-key é obrigatória')
})

interface FormDataProps {
  apiKey?: string
}

interface Props extends RouteComponentProps {}

const PagarmeModule: React.FC<Props> = ({ history }) => {
  const { showToast } = useToast()
  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const themeContext = useContext(ThemeContext)

  const { module, setModule } = useModule()

  const [isLoading, setIsLoading] = useState(false)
  const previewApiKey = watch('apiKey')

  useEffect(() => {
    setValue('apiKey', module.pagarmeModule?.apiKey, {
      shouldValidate: true
    })
  }, [module, setValue])

  const handleCancel = () => history.push('/dashboard/modules/')

  const handleButtonHelper = () =>
    window.open('https://beta.dashboard.pagar.me/#/settings/api_keys', '_blank')

  const handleUpdateStatus = useCallback(async () => {
    try {
      const pagarmeModuleData = {
        ...module.pagarmeModule,
        active: !module.pagarmeModule.active
      }

      const { data: response } = await api('user-api').put(
        `modules/pagarme/${module.pagarmeModule.id}`,
        pagarmeModuleData
      )

      setModule({
        ...module,
        pagarmeModule: pagarmeModuleData
      })

      showToast({
        type: 'success',
        message: 'Módulo atualizado com sucesso!'
      })
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Aconteceu um erro!'
      })
    }
  }, [setModule, module, showToast])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      try {
        const pagarmeModuleData = {
          apiKey: data.apiKey.trim().length > 0 ? data.apiKey : null
        }

        const { data: response } = await api('user-api').put(
          `modules/pagarme/${module.pagarmeModule.id}`,
          pagarmeModuleData
        )

        setModule({
          ...module,
          pagarmeModule: response
        })

        showToast({
          type: 'success',
          message: 'Módulo atualizado com sucesso!'
        })
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Aconteceu um erro!'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [module, setModule, showToast]
  )

  return (
    <S.Container>
      <S.Title>
        <h1>Pagarme</h1>

        <S.Helper>
          Para pegar as credencias, clique{' '}
          <button type="button" onClick={handleButtonHelper}>
            aqui
          </button>
        </S.Helper>

        <S.ModuleStatus
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={60}
          handleDiameter={12}
          offColor={themeContext.colors.red}
          onColor={themeContext.colors.green}
          checked={module.pagarmeModule.active}
          disabled={
            isLoading || !previewApiKey || previewApiKey?.trim().length === 0
          }
          onChange={handleUpdateStatus}
        />
      </S.Title>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Col>
          <S.Label htmlFor="apiKey">Digite aqui o api-key da pagarme.</S.Label>

          <S.Input
            id="apiKey"
            name="apiKey"
            className={errors.apiKey && 'is-invalid'}
            disabled={isLoading}
            {...register('apiKey')}
          />
        </S.Col>

        <S.Buttons>
          <S.Button type="button" cancel onClick={handleCancel}>
            Cancelar
          </S.Button>

          <S.Button
            type="submit"
            disabled={
              isLoading || !previewApiKey || previewApiKey?.trim().length === 0
            }
          >
            Salvar
          </S.Button>
        </S.Buttons>
      </S.Form>
    </S.Container>
  )
}

export default PagarmeModule
