export const TOKEN_KEY = process.env.REACT_APP_STAGE === 'qa' ? 'jwt' : 'twj'

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('user')
  localStorage.removeItem('last-active-service-id')
}

export const login = (token: string) => localStorage.setItem(TOKEN_KEY, token)

export const isAuthenticated = (owner: boolean) => {
  const localData = localStorage.getItem(TOKEN_KEY)

  if (localData !== null) {
    const data = JSON.parse(localStorage.getItem('user'))

    if (owner && !data.user) return false

    return true
  }
  return false
}

export const getToken = () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`
