import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'

import { PrintersModuleProps, PrinterProps } from 'utils/props'
import { useMenuPrinter, useQzTray } from 'contexts/UtilsContext'
import { useToast } from 'contexts/ToastContext'
import usePersistedState from 'hooks/usePersistedState'

import PrintersComponentStatus from './PrintersComponentStatus'
import PrinterModal from './PrinterModal'

import * as S from './styles'

const QzTrayModule: React.FC = () => {
  const { menuPrinter } = useMenuPrinter()
  const { qz, printers: printersQzTray } = useQzTray()
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)

  const [listPrinters, setListPrinters] = useState([])
  const [printersModule, setPrintersModule] =
    usePersistedState<PrintersModuleProps>('printersModule', {
      component: 'MenuPrinter',
      printers: [],
      active: false
    })

  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [printerSelected, setPrinterSelected] = useState(null)
  let time: NodeJS.Timeout = null

  useEffect(() => {
    if (printersModule.component === 'MenuPrinter') {
      setListPrinters(menuPrinter.printers())
    } else if (printersModule.component === 'QzTray') {
      setListPrinters(printersQzTray)
    }
  }, [menuPrinter, printersModule, printersQzTray])

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleChangeComponent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as 'MenuPrinter' | 'QzTray'

    setPrintersModule({
      ...printersModule,
      component: value
    })
  }

  const handleUpdateStatus = useCallback(async () => {
    try {
      setPrintersModule((prevState) => ({
        ...prevState,
        active: !prevState.active
      }))

      showToast({
        message: 'Módulo atualizado com sucesso!',
        type: 'success'
      })
    } catch (err) {
      showToast({
        message: 'Aconteceu um erro!',
        type: 'error'
      })
    }
  }, [setPrintersModule, showToast])

  const handleUpdatePrinters = useCallback(
    async (updatedPrinters: PrinterProps[]) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          setPrintersModule((prevState) => ({
            ...prevState,
            printers: updatedPrinters
          }))

          showToast({
            type: 'success',
            message: 'Impressoras atualizadas com sucesso!'
          })
        }, 1000)
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Aconteceu um erro!'
        })
      }
    },
    [showToast]
  )

  const handleMenuOptionsOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    printer: any
  ) => {
    setPrinterSelected(printer)
    setAnchorEl(event.currentTarget)
  }

  const handleMenuOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleToggleModal = (printer: any = null) => {
    setPrinterSelected(printer)
    setIsOpen((prevState: boolean) => !prevState)
  }

  const handleModalPrinterEdit = () => {
    handleMenuOptionsClose()
    handleToggleModal(printerSelected)
  }

  if (
    (printersModule.component === 'MenuPrinter' && !menuPrinter.isActive()) ||
    (printersModule.component === 'QzTray' && !qz.websocket.isActive())
  ) {
    return (
      <PrintersComponentStatus
        printersModule={printersModule}
        handleChangeComponent={handleChangeComponent}
      />
    )
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalPrinterEdit}>Editar</StyledMenuItem>
      </StyledMenu>

      <PrinterModal
        isOpen={isOpen}
        printer={printerSelected}
        printersModule={printersModule}
        setPrintersModule={setPrintersModule}
        handleToggle={handleToggleModal}
        handleUpdatePrinters={handleUpdatePrinters}
      />

      <S.Container>
        <S.Title>
          <h1>Impressoras</h1>

          <S.ModuleStatus
            onChange={handleUpdateStatus}
            checked={printersModule.active}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={60}
            handleDiameter={12}
            offColor={themeContext.colors.red}
            onColor={themeContext.colors.green}
          />
        </S.Title>

        <S.Select onChange={handleChangeComponent}>
          <option
            defaultValue={null}
            selected={!printersModule.component}
            disabled
            hidden
          >
            Selecione um componente
          </option>
          <option
            selected={printersModule.component === 'MenuPrinter'}
            value="MenuPrinter"
          >
            MenuPrinter
          </option>
          <option
            selected={printersModule.component === 'QzTray'}
            value="QzTray"
          >
            QzTray
          </option>
        </S.Select>

        <S.Printers>
          {listPrinters.map((item) => (
            <S.Printer key={item.id}>
              <S.PrinterLeft>
                <span>{item}</span>
              </S.PrinterLeft>

              <S.PrinterRight>
                <button
                  type="button"
                  title="Opções"
                  onClick={(e) => handleMenuOptionsOpen(e, item)}
                >
                  <FiMoreVertical />
                </button>
              </S.PrinterRight>
            </S.Printer>
          ))}
        </S.Printers>
      </S.Container>
    </>
  )
}

export default QzTrayModule
