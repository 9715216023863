import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Switch } from 'react-router-dom'
import moment from 'moment-timezone'

import PrivateRoute from 'components/PrivateRouter'
import routes from 'routes/menuRoutes'
import FirstTimeContext from 'contexts/FirstTimeContext'
import { api } from 'services/api'
import { TotemSelfServiceProps } from 'utils/props'
import { useTotem } from 'contexts/TotemContext'
import { useModule } from 'contexts/ModuleContext'
import { OrderProvider } from 'contexts/OrderContext'

import Navbar from './Navbar'
import Spinner from 'components/Spinner'
import Sidebar from 'components/Sidebar'

import * as S from './styles'

const TotemSelfService = (props) => {
  const { totem, setTotem } = useTotem()
  const { setModule } = useModule()

  const [isNewUser, setIsNewUser] = useState(false)
  const [serviceCreatedAt, setServiceCreatedAt] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [lastActiveServiceId, setLastActiveServiceId] = useState(
    localStorage.getItem('last-active-service-id')
  )

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true)
  }

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false)
  }

  const value = useMemo(
    () => ({ serviceCreatedAt, isNewUser }),
    [serviceCreatedAt, isNewUser]
  )

  const renderMenuRoutes = useMemo(
    () =>
      routes.map((prop) => {
        if (prop.layout.includes('/totem')) {
          return (
            <PrivateRoute
              {...props}
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={prop.name}
              exact
            />
          )
        }

        return null
      }),
    [props]
  )

  const loadServiceData = useCallback(async () => {
    setIsNewUser(false)
    setServiceCreatedAt('2020-12-14T01:52:25.973Z')
    moment.tz.setDefault('America/Bahia')
  }, [])

  const loadServices = useCallback(async () => {
    if (lastActiveServiceId) {
      setIsLoading(true)

      try {
        const { data } = await api('user-api').get<TotemSelfServiceProps>(
          `totemsselfservice/${lastActiveServiceId}`
        )
        const { data: response } = await api('user-api').get(
          `modules/${data.serviceId}`
        )

        setTotem(data)
        setModule(response)
      } finally {
        setIsLoading(false)
      }
    }
  }, [lastActiveServiceId, setModule, setTotem])

  useEffect(() => {
    loadServices()
  }, [loadServices])

  useEffect(() => {
    loadServiceData()
  }, [loadServiceData])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <OrderProvider>
      <FirstTimeContext.Provider value={value}>
        <Sidebar
          logo={
            totem?.logo
              ? totem?.logo
              : `https://avatar.oxro.io/avatar?name=${totem?.name}&background=1B242F`
          }
          title={totem?.name}
          layout="totem"
          isSidebarOpen={isSidebarOpen}
          handleCloseSidebar={handleCloseSidebar}
        />

        <S.Container>
          <S.Layout>
            <Navbar
              service={lastActiveServiceId}
              handleOpenSidebar={handleOpenSidebar}
            />

            <S.Content>
              <Switch>{renderMenuRoutes}</Switch>
            </S.Content>
          </S.Layout>
        </S.Container>
      </FirstTimeContext.Provider>
    </OrderProvider>
  )
}

export default TotemSelfService
