import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 2rem;

  ${media.lessThan('small')`
    flex-direction: column;
    justify-content: center;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  svg {
    margin-right: 1rem;
  }
`

export const ButtonCopy = styled.button`
  display: flex;
  align-items: center;

  ${media.lessThan('small')`
    margin-top: 2rem;
  `};

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  svg {
    margin-right: 1rem;
  }
`
